
import { useState, useEffect, useContext } from 'react';

import firebase from '../../services/firebaseConnection';
import { useHistory, useParams } from 'react-router-dom';

import Header from '../../components/Header';
import Title from '../../components/Title';
import avatar from '../imagens/img.png';
import { AuthContext } from '../../contexts/auth';
import { toast } from 'react-toastify';

import './parceiros.css';
import { FiPlusCircle, FiUpload } from 'react-icons/fi'

export default function Textos(){
  const { id } = useParams();
  const history = useHistory();

  const [loadParceiros, setLoadParceiros] = useState(true);
  const [parceiros, setParceiros] = useState([]);
  const [parceirosSelected, setParceirosSelected] = useState(0);

  const [nomeparceiro, setNomeparceiro] = useState('');
  const [textosimplesparceiro, setTextosimplesparceiro] = useState('');
  const [linksiteparceiro, setLinksiteparceiro] = useState('');

  const [idparceiros, setIdparceiros] = useState(false);

  const [avatarUrlparceiro, setAvatarUrlparceiro] = useState(null);
  const [avatarUrlcidader, setAvatarUrlcidader] = useState(null);
  const [imageAvatar, setImageAvatar] = useState(null);

  const { user } = useContext(AuthContext);

  useEffect(()=> {
    async function loadParceiros(){
      await firebase.firestore().collection('parceiros')
      .get()
      .then((snapshot)=>{
        let lista = [];

        snapshot.forEach((doc) => {
          lista.push({
            id: doc.id,
            nomeparceiro: doc.data().nomeparceiro
          })
        })

        if(lista.length === 0){
          console.log('NENHUMA PARCEIRO ENCONTRADO');
          setParceiros([ { id: '1', nomeparceiro: 'AVULSO' } ]);
          setLoadParceiros(false);
          return;
        }

        setParceiros(lista);
        setLoadParceiros(false);

        if(id){
          loadId(lista);
        }

      })
      .catch((error)=>{
        console.log('DEU ALGUM ERRO!', error);
        setLoadParceiros(false);
        setParceiros([ { id: '1', nomeparceiro: '' } ]);
      })

    }
    loadParceiros();
  }, [id]);


  async function loadId(lista){
    await firebase.firestore().collection('parceiros').doc(id)
    .get()
    .then((snapshot) => {
      setAvatarUrlparceiro(snapshot.data().avatarUrlparceiro);
      setNomeparceiro(snapshot.data().nomeparceiro);
      setTextosimplesparceiro(snapshot.data().textosimplesparceiro);
      setLinksiteparceiro(snapshot.data().linksiteparceiro)
      let index = lista.findIndex(item => item.id === snapshot.data().parceiroId );
      setParceirosSelected(index);
      setIdparceiros(true);

    })
    .catch((err)=>{
      console.log('ERRO NO ID PASSADO: ', err);
      setIdparceiros(false);
    })
  }

  function handleFile(e){
    if(e.target.files[0]){
      const image = e.target.files[0];
      
      if(image.type === 'image/jpeg' || image.type === 'image/png'){

        setImageAvatar(image);
        setAvatarUrlparceiro(URL.createObjectURL(e.target.files[0]))

      }else{
        alert('Envie uma imagem do tipo PNG ou JPEG');
        setImageAvatar(null);
        return null;
      }
    }
  }

  async function handleUpload(){
    toast.success('Envio Iniciado!');
    if (imageAvatar){
    const uploadTask = await firebase.storage()
    .ref(`images/parceiros/${id}/avatar/Logo`)
    .put(imageAvatar)
    .then( async () => {
      toast.success('Envio Completo!');
      await firebase.storage().ref(`images/parceiros/${id}/avatar/`)
      .child("Logo").getDownloadURL()
      .then( async (url)=>{
        let urlFoto = url;
        toast.success(`enviou corrreto ${urlFoto}`);
        
        await firebase.firestore().collection('parceiros')
        .doc(id)
        .update({
          avatarUrlparceiro: urlFoto,
          nomeparceiro: nomeparceiro,
          textosimplesparceiro: textosimplesparceiro,
          linksiteparceiro: linksiteparceiro,
          userId: user.uid
        })
        .then(()=>{
          toast.success(`Foto Enviada e Parceiro editado com sucesso! ${id}`);
          setParceirosSelected(0);
          setNomeparceiro('');
          history.push('/Parceiroslistar');
        })
      })
    })
      }
      else {
      alert('Upload failed, sorry :( 39');
    } 
  };

  async function handleRegister(e){
    e.preventDefault();

    if(idparceiros === true && imageAvatar === null){
      await firebase.firestore().collection('parceiros')
      .doc(id)
      .update({
        nomeparceiro: nomeparceiro,
        textosimplesparceiro: textosimplesparceiro,
        linksiteparceiro: linksiteparceiro,
        userId: user.uid
      })
      .then(()=>{
        toast.success('Parceiro editado com sucesso!');
        setParceirosSelected(0);
        setNomeparceiro('');
        history.push('/Parceiroslistar');
      })
      .catch((err)=>{
        toast.error('Ops erro ao registrar, tente mais tarde.')
        console.log(err);
      })

    }
    else if (idparceiros === true && imageAvatar !== null){
      handleUpload();
    }

    else if(idparceiros === false){

    await firebase.firestore().collection('parceiros')
    .add({
      created: new Date(),
      nomeparceiro: nomeparceiro,
      textosimplesparceiro: textosimplesparceiro,
      linksiteparceiro: linksiteparceiro,
      userId: user.uid
    })
    .then(()=> {
      toast.success('Parceiro cadastrado com sucesso!');
      setNomeparceiro('');
      setTextosimplesparceiro('');
      setLinksiteparceiro('');
      setParceirosSelected(0);
      history.push('/Parceiroslistar');
    })
    .catch((err)=> {
      toast.error('Ops erro ao registrar, tente mais tarde.')
      console.log(err);
    })
  }
  }


  return(
    <div>
      <Header/>

      <div className="content">
        <Title name="Parceiros">
          <FiPlusCircle size={25} />
        </Title>

        <div className="container">

          <form className="form-profile"  onSubmit={handleRegister} >
            <label>Nome Parceiro</label>
            <input type="text" placeholder="Nome" value={nomeparceiro} onChange={ (e) => setNomeparceiro(e.target.value) } />
            { idparceiros === true ?
            <label className="label-avatar">
              <span>
                <FiUpload color="#FFF" size={25} />
              </span>

              <input type="file" accept="image/*" onChange={handleFile}  /><br/>
              { avatarUrlparceiro == null ? 
                <img src={avatar} width="250" height="250" alt="Sem Logo Parceiro" />
                :
                <img src={avatarUrlparceiro} width="250" height="250" alt="Logo Parceiro" />
              }
            </label>
            :
            <label className="label-avatar"> Salve para colocar a logomarca, use PNG com fundo transparante!
              <span>
                <FiUpload color="#000" size={25} />
              </span>
            </label>
          }
            <label>Texto Simples - 100 caracteres</label>
            <textarea
              type="text"
              placeholder="texto simples"
              value={textosimplesparceiro}
              onChange={ (e) => setTextosimplesparceiro(e.target.value) }
            />
            <label>Link site</label>
            <input type="text" placeholder="link sem o http://" value={linksiteparceiro} onChange={ (e) => setLinksiteparceiro(e.target.value) } />
            
            <button type="submit">Registrar</button>

          </form>

        </div>

      </div>
    </div>
  )
}