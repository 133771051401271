
import { useState, useContext } from 'react';
import { Switch } from 'react-router-dom';
import Route from './Route';
import { AuthContext } from '../contexts/auth';

import Site from '../pages/Site';
import Siteponto from '../pages/Siteponto';
import Siterota from '../pages/Siterota';
import SignIn from '../pages/SignIn';
import SignUp from '../pages/SignUp';

import Dashboard from '../pages/Dashboard';
import Config from '../pages/Config';
import Configlista from '../pages/Configlistar';
import Profile from '../pages/Profile';
import Textos from '../pages/Textos';
import Textoslista from '../pages/Textoslistar';
import Cidades from '../pages/Cidades';
import Cidadeslista from '../pages/Cidadeslistar';
import Seção from '../pages/Secao';
import Projetos from '../pages/Projetos';
import Projetoslista from '../pages/Projetoslistar';
import Trabalhos from '../pages/Trabalhos';
import Trabalhoslista from '../pages/Trabalhoslistar';
import Pontosdeinteresse from '../pages/Pontosinteresse';
import Pontosdeinteresselista from '../pages/Pontosinteresselistar';
import Rotas from '../pages/Rotas';
import Rotaslista from '../pages/Rotaslistar';
import Imprensa from '../pages/Imprensa';
import Imprensalista from '../pages/Imprensalistar';
import Parceiros from '../pages/Parceiros';
import Parceiroslista from '../pages/Parceiroslistar';
import Igrs from '../pages/Igrs';
import Igrslista from '../pages/Igrslistar';
import Imgs from '../pages/Imgs';
import Calendario from '../pages/Calendario';
import Calendariolista from '../pages/Calendariolistar';

export default function Routes(){
  const { user } = useContext(AuthContext);
  const [tipo, setTipo] = useState(user && user.tipo);
  if (tipo==='adm'){
    return(
        <Switch>
        <Route exact path="/" component={Site} />
        <Route exact path="/siteponto/:id2" component={Siteponto} />
        <Route exact path="/siterota" component={Siterota} />
        <Route exact path="/login" component={SignIn} />
        <Route exact path="/register" component={SignUp} />
        <Route exact path="/dashboard" component={Dashboard} isPrivate />
        <Route exact path="/profile" component={Profile} isPrivate />

        <Route exact path="/config" component={Config} isPrivate />
        <Route exact path="/config/:id" component={Config} isPrivate />
        <Route exact path="/configlistar" component={Configlista} isPrivate />
        <Route exact path="/imgs" component={Imgs} isPrivate />        
        <Route exact path="/textos" component={Textos} isPrivate />
        <Route exact path="/textos/:id" component={Textos} isPrivate />
        <Route exact path="/textoslistar" component={Textoslista} isPrivate />
        <Route exact path="/trabalhos" component={Trabalhos} isPrivate />
        <Route exact path="/trabalhos/:id" component={Trabalhos} isPrivate />
        <Route exact path="/trabalhoslistar" component={Trabalhoslista} isPrivate />
        <Route exact path="/cidades" component={Cidades} isPrivate />
        <Route exact path="/cidades/:id" component={Cidades} isPrivate />
        <Route exact path="/cidadeslistar" component={Cidadeslista} isPrivate />
        <Route exact path="/secao" component={Seção} isPrivate />
        <Route exact path="/projetos" component={Projetos} isPrivate />
        <Route exact path="/projetos/:id" component={Projetos} isPrivate />
        <Route exact path="/projetoslistar" component={Projetoslista} isPrivate />
        <Route exact path="/pontosdeinteresse" component={Pontosdeinteresse} isPrivate />
        <Route exact path="/pontosdeinteresse/:id" component={Pontosdeinteresse} isPrivate />
        <Route exact path="/pontosdeinteresselistar" component={Pontosdeinteresselista} isPrivate />
        <Route exact path="/rotas" component={Rotas} isPrivate />
        <Route exact path="/rotas/:id" component={Rotas} isPrivate />
        <Route exact path="/rotaslistar" component={Rotaslista} isPrivate />
        <Route exact path="/imprensa" component={Imprensa} isPrivate />
        <Route exact path="/imprensa/:id" component={Imprensa} isPrivate />
        <Route exact path="/imprensalistar" component={Imprensalista} isPrivate />
        <Route exact path="/parceiros" component={Parceiros} isPrivate />
        <Route exact path="/parceiros/:id" component={Parceiros} isPrivate />
        <Route exact path="/parceiroslistar" component={Parceiroslista} isPrivate />
        <Route exact path="/igrs" component={Igrs} isPrivate />
        <Route exact path="/igrs/:id" component={Igrs} isPrivate />
        <Route exact path="/igrslistar" component={Igrslista} isPrivate />
        <Route exact path="/calendario" component={Calendario} isPrivate />
        <Route exact path="/calendario/:id" component={Calendario} isPrivate />
        <Route exact path="/calendariolistar" component={Calendariolista} isPrivate />
        </Switch>
  )
}
else {
  return(
      <Switch>
        <Route exact path="/" component={Site} />
        <Route exact path="/siteponto/:id2" component={Siteponto} />
        <Route exact path="/siterota" component={Siterota} />
        <Route exact path="/login" component={SignIn} />
        <Route exact path="/register" component={SignUp} />
        <Route exact path="/dashboard" component={Dashboard} isPrivate />
        <Route exact path="/profile" component={Profile} isPrivate />

        <Route exact path="/config" component={Config} isPrivate />
        <Route exact path="/config/:id" component={Config} isPrivate />
        <Route exact path="/configlistar" component={Configlista} isPrivate />
        <Route exact path="/imgs" component={Imgs} isPrivate />        
        <Route exact path="/textos" component={Textos} isPrivate />
        <Route exact path="/textos/:id" component={Textos} isPrivate />
        <Route exact path="/textoslistar" component={Textoslista} isPrivate />
        <Route exact path="/trabalhos" component={Trabalhos} isPrivate />
        <Route exact path="/trabalhos/:id" component={Trabalhos} isPrivate />
        <Route exact path="/trabalhoslistar" component={Trabalhoslista} isPrivate />
        <Route exact path="/cidades" component={Cidades} isPrivate />
        <Route exact path="/cidades/:id" component={Cidades} isPrivate />
        <Route exact path="/cidadeslistar" component={Cidadeslista} isPrivate />
        <Route exact path="/secao" component={Seção} isPrivate />
        <Route exact path="/projetos" component={Projetos} isPrivate />
        <Route exact path="/projetos/:id" component={Projetos} isPrivate />
        <Route exact path="/projetoslistar" component={Projetoslista} isPrivate />
        <Route exact path="/pontosdeinteresse" component={Pontosdeinteresse} isPrivate />
        <Route exact path="/pontosdeinteresse/:id" component={Pontosdeinteresse} isPrivate />
        <Route exact path="/pontosdeinteresselistar" component={Pontosdeinteresselista} isPrivate />
        <Route exact path="/rotas" component={Rotas} isPrivate />
        <Route exact path="/rotas/:id" component={Rotas} isPrivate />
        <Route exact path="/rotaslistar" component={Rotaslista} isPrivate />
        <Route exact path="/imprensa" component={Imprensa} isPrivate />
        <Route exact path="/imprensa/:id" component={Imprensa} isPrivate />
        <Route exact path="/imprensalistar" component={Imprensalista} isPrivate />
        <Route exact path="/parceiros" component={Parceiros} isPrivate />
        <Route exact path="/parceiros/:id" component={Parceiros} isPrivate />
        <Route exact path="/parceiroslistar" component={Parceiroslista} isPrivate />
        <Route exact path="/igrs" component={Igrs} isPrivate />
        <Route exact path="/igrs/:id" component={Igrs} isPrivate />
        <Route exact path="/igrslistar" component={Igrslista} isPrivate />
        <Route exact path="/calendario" component={Calendario} isPrivate />
        <Route exact path="/calendario/:id" component={Calendario} isPrivate />
        <Route exact path="/calendariolistar" component={Calendariolista} isPrivate />
      </Switch>
)
}

}