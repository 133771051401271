import './modal.css';
import { useState, useEffect } from 'react';
import { FaFacebookSquare, FaInstagram, FaWhatsappSquare, FaLaptop } from "react-icons/fa";
import { FiX } from 'react-icons/fi';
import { ScrollView } from "@cantonjs/react-scroll-view";
import { format } from 'date-fns';
import YouTube from 'react-youtube';
import firebase from '../../services/firebaseConnection';
import { Link } from 'react-router-dom';

const optsmodal = {
  height: '390',
  width: '480',
  playerVars: {
    // https://developers.google.com/youtube/player_parameters
    autoplay: 0,
  },
};
const listRef2 = firebase.firestore().collection('pontosinteresse').orderBy('nomeponto', 'asc');

export default function Modaleduca({conteudo4, close}){
  const [pontos, setPontos] = useState([]);
  const [loading2, setLoading2] = useState(true);
  const [loadingMore2, setLoadingMore2] = useState(false);
  const [isEmpty2, setIsEmpty2] = useState(false);
  const [lastDocs2, setLastDocs2] = useState();
  useEffect(()=> {
    async function loadPontos(){
      await listRef2.limit(200)
      .get()
      .then((snapshot) => {
        updateStatepontos(snapshot)
      })
      .catch((err)=>{
        console.log('Deu algum erro: ', err);
        setLoadingMore2(false);
      })
  
      setLoading2(false);
    }
    loadPontos();
    return () => {

    }
  }, []);

  async function updateStatepontos(snapshot){
    const isCollectionEmpty2 = snapshot.size === 0;

    if(!isCollectionEmpty2){
      let lista2 = [];

      snapshot.forEach((doc)=>{
        lista2.push({
          id2: doc.id,
          nomeponto: doc.data().nomeponto,
          textosimplesponto: doc.data().textosimplesponto,
          categoriaponto: doc.data().categoriaponto,
          cidadeponto: doc.data().cidadeponto,
          gpsponto: doc.data().textocompletopgpspontoonto,
          logradouroponto: doc.data().logradouroponto,
          numeroponto: doc.data().numeroponto,
          created: doc.data().created,
          createdFormated: format(doc.data().created.toDate(), 'dd/MM/yyyy')

        })
      })

      const lastDoc2 = snapshot.docs[snapshot.docs.length -1]; //Pegando o ultimo documento buscado
      
      setPontos(pontos => [...pontos, ...lista2]);
      setLastDocs2(lastDoc2);

    }else{
      setIsEmpty2(true);
    }
    setLoadingMore2(false);
  }

  function onReady(event) {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
  }

  return(
    <div className="modal">
      <div className="container">
        <button className="close" onClick={ close }>
          <FiX size={23} color="#FFF" />
          Fechar
        </button>
        <div className="row">
        <h2>{conteudo4}</h2>
        </div>
        <div className="row">
        <ScrollView style={{ height: '45vh', widht: '100%'  }}>

        {pontos.map((item5, index)=>{
            if (item5.categoriaponto===conteudo4){
              return(
                    <div className="row">
                      <span>
                      <Link className="action" to={`/siteponto/${item5.id2}`} >{item5.nomeponto}</Link> 
                      </span>
                    </div>
              )
            }
                })
              }

        </ScrollView>
        </div>
      </div>
    </div>
  )
}