
import { useState, useEffect, useContext } from 'react';

import firebase from '../../services/firebaseConnection';
import { useHistory, useParams } from 'react-router-dom';

import Header from '../../components/Header';
import Title from '../../components/Title';
import avatar from '../imagens/img.png';
import { AuthContext } from '../../contexts/auth';
import { toast } from 'react-toastify';

import './igrs.css';
import { FiPlusCircle, FiUpload } from 'react-icons/fi'

export default function Textos(){
  const { id } = useParams();
  const history = useHistory();
  const { user } = useContext(AuthContext);

  const [loadIgrs, setLoadIgrs] = useState(true);
  const [igrs, setIgrs] = useState([]);
  const [igrsSelected, setIgrsSelected] = useState(0);

  const [nomeigr, setNomeigr] = useState('');
  const [cidadesigr, setCidadesigr] = useState('');
  const [estadoigr, setEstadoigr] = useState('');
  const [textosimplesigr, setTextosimplesigr] = useState('');
  const [textocompletoigr, setTextocompletoigr] = useState('');
  const [linkfbigr, setLinkfbigr] = useState('');
  const [linkinstagramigr, setLinkinstagramigr] = useState('');
  const [linksiteigr, setLinksiteigr] = useState('');
  const [linkemailigr, setLinkemailigr] = useState('');
  const [linktelefoneigr, setLinktelefoneigr] = useState('');
  const [idIgrs, setIdIgrs] = useState(false);

  const [avatarUrligr, setAvatarUrligr] = useState(null);
  const [avatarUrligrr, setAvatarUrligrr] = useState(null);
  const [imageAvatar, setImageAvatar] = useState(null);

  const [uploading, setUploading] = useState(false);

  useEffect(()=> {
    async function loadIgrs(){
      await firebase.firestore().collection('igrs')
      .get()
      .then((snapshot)=>{
        let lista = [];

        snapshot.forEach((doc) => {
          lista.push({
            id: doc.id,
            nomeigr: doc.data().nomeigr
          })
        })

        if(lista.length === 0){
          console.log('NENHUMA CIDADE ENCONTRADA');
          setIgrs([ { id: '1', nomeigr: 'AVULSO' } ]);
          setLoadIgrs(false);
          return;
        }

        setIgrs(lista);
        setLoadIgrs(false);

        if(id){
          loadId(lista);
        }

      })
      .catch((error)=>{
        console.log('DEU ALGUM ERRO!', error);
        setLoadIgrs(false);
        setIgrs([ { id: '1', nomeigr: '' } ]);
      })

    }
    loadIgrs();
  }, [id]);


  async function loadId(lista){
    await firebase.firestore().collection('igrs').doc(id)
    .get()
    .then((snapshot) => {
      setAvatarUrligr(snapshot.data().avatarUrligr);
      setNomeigr(snapshot.data().nomeigr);
      setCidadesigr(snapshot.data().cidadesigr);
      setEstadoigr(snapshot.data().estadoigr);
      setTextosimplesigr(snapshot.data().textosimplesigr);
      setTextocompletoigr(snapshot.data().textocompletoigr);
      setLinkfbigr(snapshot.data().linkfbigr);
      setLinkinstagramigr(snapshot.data().linkinstagramigr);
      setLinksiteigr(snapshot.data().linksiteigr);
      setLinkemailigr(snapshot.data().linkemailigr);
      setLinktelefoneigr(snapshot.data().linktelefoneigr)

      let index = lista.findIndex(item => item.id === snapshot.data().projetoId );
      setIgrsSelected(index);
      setIdIgrs(true);

    })
    .catch((err)=>{
      console.log('ERRO NO ID PASSADO: ', err);
      setIdIgrs(false);
    })
  }

  function handleFile(e){
    if(e.target.files[0]){
      const image = e.target.files[0];
      
      if(image.type === 'image/jpeg' || image.type === 'image/png'){

        setImageAvatar(image);
        setAvatarUrligr(URL.createObjectURL(e.target.files[0]))

      }else{
        alert('Envie uma imagem do tipo PNG ou JPEG');
        setImageAvatar(null);
        return null;
      }
    }
  }

  async function handleUpload(){
    toast.success('Envio Iniciado!');
    if (imageAvatar){
    const uploadTask = await firebase.storage()
    .ref(`images/igrs/${id}/avatar/Avatar`)
    .put(imageAvatar)
    .then( async () => {
      toast.success('Envio Completo!');
      await firebase.storage().ref(`images/igrs/${id}/avatar/`)
      .child("Avatar").getDownloadURL()
      .then( async (url)=>{
        let urlFoto = url;
        toast.success(`enviou corrreto ${urlFoto}`);
        
        await firebase.firestore().collection('igrs')
        .doc(id)
        .update({
          avatarUrligr: urlFoto,
          nomeigr: nomeigr,
          cidadesigr: cidadesigr,
          estadoigr: estadoigr,
          textosimplesigr: textosimplesigr,
          textocompletoigr: textocompletoigr,
          linkfbigr: linkfbigr,
          linkinstagramigr: linkinstagramigr,
          linksiteigr: linksiteigr,
          linkemailigr: linkemailigr,
          linktelefoneigr: linktelefoneigr,
          userId: user.uid
        })
        .then(()=>{
          toast.success(`Foto Enviada e Cidade editada com sucesso! ${id}`);
          setIgrsSelected(0);
          setNomeigr('');
          history.push('/igrslistar');
        })
      })
    })
      }
      else {
      alert('Upload failed, sorry :( 39');
    } 
  };


  async function handleRegister(e){
    e.preventDefault();

    if(idIgrs === true && imageAvatar === null){
      await firebase.firestore().collection('igrs')
      .doc(id)
      .update({
        nomeigr: nomeigr,
        cidadesigr: cidadesigr,
        estadoigr: estadoigr,
        textosimplesigr: textosimplesigr,
        textocompletoigr: textocompletoigr,
        linkfbigr: linkfbigr,
        linkinstagramigr: linkinstagramigr,
        linksiteigr: linksiteigr,
        linkemailigr: linkemailigr,
        linktelefoneigr: linktelefoneigr,
        userId: user.uid
      })
      .then(()=>{
        toast.success('Cidade editada com sucesso!');
        setIgrsSelected(0);
        setNomeigr('');
        history.push('/igrslistar');
      })
      .catch((err)=>{
        toast.error('Ops erro ao registrar, tente mais tarde.')
        console.log(err);
      })

    }
    else if (idIgrs === true && imageAvatar !== null){
      handleUpload();
    }

    else if(idIgrs === false){

    await firebase.firestore().collection('igrs')
    .add({
      created: new Date(),
      nomeigr: nomeigr,
      cidadesigr: cidadesigr,
      estadoigr: estadoigr,
      textosimplesigr: textosimplesigr,
      textocompletoigr: textocompletoigr,
      linkfbigr: linkfbigr,
      linkinstagramigr: linkinstagramigr,
      linksiteigr: linksiteigr,
      linkemailigr: linkemailigr,
      linktelefoneigr: linktelefoneigr,
      userId: user.uid
    })
    .then(()=> {
      toast.success('Cidade cadastrada com sucesso!');
      setNomeigr('');
      setEstadoigr('');
      setTextosimplesigr('');
      setTextocompletoigr('');
      setLinkfbigr('');
      setLinkinstagramigr('');
      setLinksiteigr('');
      setIgrsSelected(0);
      history.push('/igrslistar');
    })
    .catch((err)=> {
      toast.error('Ops erro ao registrar, tente mais tarde.')
      console.log(err);
    })
  }

  }


  //Chamado quando troca o assunto
  function handleChangeEstado(e){
    setEstadoigr(e.target.value);
  }


  return(
    <div>
      <Header/>

..      <div className="content">
        <Title name="Nova IGR">
          <FiPlusCircle size={25} />
        </Title>

        <div className="container">

          <form className="form-profile"  onSubmit={handleRegister} >
            <label>IGRs</label>
            <input type="text" placeholder="Nome da igr" value={nomeigr} onChange={ (e) => setNomeigr(e.target.value) } />
            <label>Cidades</label>
            <textarea
              type="text"
              placeholder="nomes separados por virgula"
              value={cidadesigr}
              onChange={ (e) => setCidadesigr(e.target.value) }
            />
            <label>Estado</label>
            <select value={estadoigr} onChange={handleChangeEstado}>
              <option value="MG">Mina Gerais</option>
              <option value="RJ">Rio de Janeiro</option>
            </select>
            { idIgrs === true ?
            <label className="label-avatar">
              <span>
                <FiUpload color="#FFF" size={25} />
              </span>

              <input type="file" accept="image/*" onChange={handleFile}  /><br/>
              { avatarUrligr == null ? 
                <img src={avatar} width="250" height="250" alt="Sem Foto IGR" />
                :
                <img src={avatarUrligr} width="250" height="250" alt="Foto IGR" />
              }
            </label>
            :
            <label className="label-avatar">Complete o registro de texto liberar a colocação de foto.
              <span>
                <FiUpload color="#000" size={25} />
              </span>
            </label>
          }
            <label>Texto Simples - 100 caracteres</label>
            <textarea
              type="text"
              placeholder="texto simples"
              value={textosimplesigr}
              onChange={ (e) => setTextosimplesigr(e.target.value) }
            />

            <label>Texto Completo - 500 caracteres</label>
            <textarea
              type="text"
              placeholder="texto completo"
              value={textocompletoigr}
              onChange={ (e) => setTextocompletoigr(e.target.value) }
            />

            <label>Facebook</label>
            <input type="text" placeholder="link Facebook" value={linkfbigr} onChange={ (e) => setLinkfbigr(e.target.value) } />
            <label>Instagram</label>
            <input type="text" placeholder="link Instagram" value={linkinstagramigr} onChange={ (e) => setLinkinstagramigr(e.target.value) } />
            <label>Página</label>
            <input type="text" placeholder="link" value={linksiteigr} onChange={ (e) => setLinksiteigr(e.target.value) } />
            <label>E-mail</label>
            <input type="text" placeholder="email" value={linkemailigr} onChange={ (e) => setLinkemailigr(e.target.value) } />
            <label>Telefone</label>
            <input type="text" placeholder="(XX)XXXX-XXXX" value={linktelefoneigr} onChange={ (e) => setLinktelefoneigr(e.target.value) } />

            <button type="submit">Registrar</button>

          </form>

        </div>

      </div>
    </div>
  )
}