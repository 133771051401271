
import './site.css';
import { useState, useEffect } from 'react';
import Header from '../../components/Header';
import Title from '../../components/Title';
import { FiMessageSquare, FiPlus, FiSearch, FiEdit2, FiSettings } from 'react-icons/fi';
import { FaLinkedin, FaFacebookSquare, FaInstagram, FaWhatsappSquare, FaLaptop, FaYoutube, FaApple, FaAndroid } from "react-icons/fa";
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import { ScrollView } from "@cantonjs/react-scroll-view";
import { Zoom } from 'react-slideshow-image';
import YouTube from 'react-youtube';

import firebase from '../../services/firebaseConnection';
import Modalcidade from '../../components/Modalcidade';
import Modalimprensa from '../../components/Modalimprensa';
import Modalrota from '../../components/Modalrota';
import Modaleduca from '../../components/Modaleduca';

import logo from '../imagens/logo.png';
import avatar from '../imagens/img.png';

import casario from '../imagens/Casario.png';
import gastronomia from '../imagens/Gastronomia.png';
import igrejas from '../imagens/Igrejas.png';
import natureza from '../imagens/Natureza.png';
import museus from '../imagens/Museus.png';
import outros from '../imagens/Outros.png';

import religioso from '../imagens/EReligioso.png';
import festas from '../imagens/EFestas.png';
import eventos from '../imagens/EEventos.png';

import patrimoniominas from '../imagens/patrimoniominas.png';
import imgtiradentes from '../imagens/imgtiradentes.png';

import imgmapa from '../imagens/mapa.png';
import imgvideo from '../imagens/video.png';

import Maps1 from '../../components/Mapsigr';



const zoomInProperties = {
  indicators: true,
  scale: 1.4,
  backgroundColor: '#000'
};

const opts = {
  height: '400',
  width: '800',
  playerVars: {
    // https://developers.google.com/youtube/player_parameters
    autoplay: 0,
  },
};



const listRef = firebase.firestore().collection('cidades').orderBy('nomecidade', 'asc');
const listRef2 = firebase.firestore().collection('pontosinteresse').orderBy('nomeponto', 'asc');
const listRef3 = firebase.firestore().collection('rotas').orderBy('created', 'asc');
const listRef4 = firebase.firestore().collection('parceiros').orderBy('created', 'asc');
const listRef5 = firebase.firestore().collection('imprensa').orderBy('created', 'asc');

export default function Dashboard(){
  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);
  const [lastDocs, setLastDocs] = useState();
 
  const [loading2, setLoading2] = useState(true);
  const [loadingMore2, setLoadingMore2] = useState(false);
  const [isEmpty2, setIsEmpty2] = useState(false);
  const [lastDocs2, setLastDocs2] = useState();

  const [loading3, setLoading3] = useState(true);
  const [loadingMore3, setLoadingMore3] = useState(false);
  const [isEmpty3, setIsEmpty3] = useState(false);
  const [lastDocs3, setLastDocs3] = useState();

  const [loading4, setLoading4] = useState(true);
  const [loadingMore4, setLoadingMore4] = useState(false);
  const [isEmpty4, setIsEmpty4] = useState(false);
  const [lastDocs4, setLastDocs4] = useState();

  const [loading5, setLoading5] = useState(true);
  const [loadingMore5, setLoadingMore5] = useState(false);
  const [isEmpty5, setIsEmpty5] = useState(false);
  const [lastDocs5, setLastDocs5] = useState();

  const [loading6, setLoading6] = useState(true);
  const [loadingMore6, setLoadingMore6] = useState(false);
  const [isEmpty6, setIsEmpty6] = useState(false);
  const [lastDocs6, setLastDocs6] = useState();

  const [complemento, setComplemento] = useState();
  const [texto1, setTexto1] = useState();
  const [texto2, setTexto2] = useState();
  const [texto3, setTexto3] = useState();
  const [texto4, setTexto4] = useState();
  const [linkvideo, setLinkvideo] = useState();
  const [texto5, setTexto5] = useState();
  const [texto6, setTexto6] = useState();

  const [banner1, setBanner1] = useState();
  const [banner2, setBanner2] = useState();
  const [banner3, setBanner3] = useState();
  const [banner4, setBanner4] = useState();
  const [banner5, setBanner5] = useState();
  const [banner6, setBanner6] = useState();

  const images = [
    {imgbanner: banner1},
    {imgbanner: banner2},
    {imgbanner: banner3},
    {imgbanner: banner4},
    {imgbanner: banner5},
    {imgbanner: banner6}
  ];
  
  const [nomeconfig, setNomeconfig] = useState('');
  const [sloganconfig, setSloganconfig]= useState('');
  const [desccurtaconfig, setDesccurtaconfig] = useState('');
  const [palavrachaveconfig, setPalavrachaveconfig] = useState('');
  const [razaoconfig, setRazaoconfig] = useState('');
  const [cnpjconfig, setCnpjconfig] = useState('');
  const [cepconfig, setCepconfig] = useState('');
  const [logradouroconfig, setLogradouroconfig] = useState('');
  const [numeroconfig, setNumeroconfig] = useState('');
  const [complementoconfig, setComplementoconfig] = useState('');
  const [bairroconfig, setBairroconfig] = useState('');
  const [cidadeconfig, setCidadeconfig] = useState('');
  const [estadoconfig, setEstadoconfig] = useState('');
  const [paisconfig, setPaisconfig] = useState('');
  const [siteconfig, setSiteconfig] = useState('');
  const [emailconfig, setEmailconfig] = useState('');
  const [linkinstagramconfig, setLinkinstagramconfig] = useState('');
  const [linkfbconfig, setLinkfbconfig] = useState('');
  const [linkyoutubeconfig, setLinkyoutubeconfig] = useState('');
  const [linkdriveconfig, setLinkdriveconfig] = useState('');
  const [linkdrivelegisconfig, setLinkdrivelegisconfig] = useState('');
  const [linkdrivetransconfig, setLinkdrivetransconfig] = useState('');
  const [linkdrivefotoconfig, setLinkdrivefotoconfig] = useState('');
  const [linklinkedconfig, setLinklinkedconfig] = useState('');
  const [linkapplestoreconfig, setLinkapplestoreconfig] = useState('');
  const [linkgoogleplayconfig, setLinkgoogleplayconfig] = useState('');
  const [telefone1config, setTelefone1config] = useState('');
  const [telefone2config, setTelefone2config] = useState('');
  const [whats1config, setWhats1config] = useState('');
  const [whats2config, setWhats2config] = useState('');

  const [cidades, setCidades] = useState([]);
  const [pontos, setPontos] = useState([]);
  const [rotas, setRotas] = useState([]);
  const [parceiros, setParceiros] = useState([]);
  const [imprensa, setImprensa] = useState([]);
  const [igr, setIgr] = useState([]);

  const [showPostModal, setShowPostModal] = useState(false);
  const [detail, setDetail] = useState();
  
  const [showPostModal2, setShowPostModal2] = useState(false);
  const [detail2, setDetail2] = useState();

  const [showPostModal3, setShowPostModal3] = useState(false);
  const [detail3, setDetail3] = useState();

  const [showPostModal4, setShowPostModal4] = useState(false);
  const [detail4, setDetail4] = useState();

  useEffect(()=> {

    async function loadTextos(){
      await firebase.firestore().collection('textos')
      .doc('RUNqoWqNjd76Hs8i9HWw')
      .get()
      .then((snapshot) => {
        setComplemento(snapshot.data().complemento);  
        setTexto1(snapshot.data().texto1);
        setTexto2(snapshot.data().texto2);
        setTexto3(snapshot.data().texto3);
        setTexto4(snapshot.data().texto4);
        setLinkvideo(snapshot.data().linkvideo);
        setTexto5(snapshot.data().texto5);
        setTexto6(snapshot.data().texto6)
      })

    }
    loadTextos();

    async function loadBanners(){
      await firebase.firestore().collection('imagens')
      .doc('KCmp3x0Bpw8xc3iS0fNA')
      .get()
      .then((snapshot) => {
        setBanner1(snapshot.data().bannersup1);
        setBanner2(snapshot.data().bannersup2);
        setBanner3(snapshot.data().bannersup3);
        setBanner4(snapshot.data().bannersup4);
        setBanner5(snapshot.data().bannersup5);
        setBanner6(snapshot.data().bannersup6);
      })

    }
    loadBanners();

    async function loadConfig(){
      await firebase.firestore().collection('config')
      .doc('GH3GCwt8q6huZCRz17eh')
      .get()
      .then((snapshot) => {
        setNomeconfig(snapshot.data().nomeconfig);
        setSloganconfig(snapshot.data().sloganconfig);
        setDesccurtaconfig(snapshot.data().desccurtaconfig);
        setPalavrachaveconfig(snapshot.data().palavrachaveconfig);
        setRazaoconfig(snapshot.data().razaoconfig);
        setCnpjconfig(snapshot.data().cnpjconfig);
        setCepconfig(snapshot.data().cepconfig);
        setLogradouroconfig(snapshot.data().logradouroconfig);
        setNumeroconfig(snapshot.data().numeroconfig);
        setComplementoconfig(snapshot.data().complementoconfig);
        setBairroconfig(snapshot.data().bairroconfig);
        setCidadeconfig(snapshot.data().cidadeconfig);
        setEstadoconfig(snapshot.data().estadoconfig);
        setPaisconfig(snapshot.data().paisconfig);
        setSiteconfig(snapshot.data().siteconfig);
        setEmailconfig(snapshot.data().emailconfig);
        setLinkinstagramconfig(snapshot.data().linkinstagramconfig);
        setLinkfbconfig(snapshot.data().linkfbconfig);
        setLinkyoutubeconfig(snapshot.data().linkyoutubeconfig);
        setLinkdriveconfig(snapshot.data().linkdriveconfig);
        setLinkdrivelegisconfig(snapshot.data().linkdrivelegisconfig);
        setLinkdrivetransconfig(snapshot.data().linkdrivetransconfig);
        setLinkdrivefotoconfig(snapshot.data().linkdrivefotoconfig);
        setLinklinkedconfig(snapshot.data().linklinkedconfig);
        setLinkapplestoreconfig(snapshot.data().linkapplestoreconfig);
        setLinkgoogleplayconfig(snapshot.data().linkgoogleplayconfig);
        setTelefone1config(snapshot.data().telefone1config);
        setTelefone2config(snapshot.data().telefone2config);
        setWhats1config(snapshot.data().whats1config);
        setWhats2config(snapshot.data().whats2config)
      })
    }
    loadConfig();


    async function loadPontos(){
      await listRef2
      .get()
      .then((snapshot) => {
        updateStatepontos(snapshot)
      })
      .catch((err)=>{
        console.log('Deu algum erro: ', err);
        setLoadingMore2(false);
      })
  
      setLoading2(false);
    }
    loadPontos();

    async function loadRotas(){
      await listRef3.limit(10)
      .get()
      .then((snapshot) => {
        updateStaterotas(snapshot)
      })
      .catch((err)=>{
        console.log('Deu algum erro: ', err);
        setLoadingMore3(false);
      })
  
      setLoading3(false);
    }
    loadRotas();

    async function loadParceiros(){
      await listRef4.limit(10)
      .get()
      .then((snapshot) => {
        updateStateparceiros(snapshot)
      })
      .catch((err)=>{
        console.log('Deu algum erro: ', err);
        setLoadingMore4(false);
      })
  
      setLoading4(false);
    }
    loadParceiros();
    
       

    return () => {

    }
  }, []);


  async function updateStatepontos(snapshot){
    const isCollectionEmpty2 = snapshot.size === 0;

    if(!isCollectionEmpty2){
      let lista2 = [];

      snapshot.forEach((doc)=>{
        lista2.push({
          id2: doc.id,
          nomeponto: doc.data().nomeponto,
          textosimplesponto: doc.data().textosimplesponto,
          categoriaponto: doc.data().categoriaponto,
          cidadeponto: doc.data().cidadeponto,
          gpsponto: doc.data().textocompletopgpspontoonto,
          logradouroponto: doc.data().logradouroponto,
          numeroponto: doc.data().numeroponto,
          created: doc.data().created,
          createdFormated: format(doc.data().created.toDate(), 'dd/MM/yyyy')

        })
      })

      const lastDoc2 = snapshot.docs[snapshot.docs.length -1]; //Pegando o ultimo documento buscado
      
      setPontos(pontos => [...pontos, ...lista2]);
      setLastDocs2(lastDoc2);

    }else{
      setIsEmpty2(true);
    }
    setLoadingMore2(false);
  }

  async function updateStaterotas(snapshot){
    const isCollectionEmpty3 = snapshot.size === 0;

    if(!isCollectionEmpty3){
      let lista3 = [];

      snapshot.forEach((doc)=>{
        lista3.push({
          id3: doc.id,
          avatarUrlrota: doc.data().avatarUrlrota,
          nomerota: doc.data().nomerota,
          estadorota: doc.data().estadorota,
          textosimplesrota: doc.data().textosimplesrota,
          textocompletorota: doc.data().textocompletorota,
          cidadesrota: doc.data().cidadesrota,
          estadorota: doc.data().estadorota,
          dadoskmrota: doc.data().dadoskmrota,
          midiagpxkmlrota: doc.data().midiagpxkmlrota,
          created: doc.data().created,
          createdFormated: format(doc.data().created.toDate(), 'dd/MM/yyyy')

        })
      })

      const lastDoc3 = snapshot.docs[snapshot.docs.length -1]; //Pegando o ultimo documento buscado
      setRotas(rotas => [...rotas, ...lista3]);
      setLastDocs3(lastDoc3);
    }else{
      setIsEmpty3(true);
    }
    setLoadingMore3(false);
  }

  async function updateStateparceiros(snapshot){
    const isCollectionEmpty4 = snapshot.size === 0;

    if(!isCollectionEmpty4){
      let lista4 = [];

      snapshot.forEach((doc)=>{
        lista4.push({
          id4: doc.id,
          avatarUrlparceiro: doc.data().avatarUrlparceiro,
          nomeparceiro: doc.data().nomeparceiro,
          linksiteparceiro: doc.data().linksiteparceiro,
          created: doc.data().created,
          createdFormated: format(doc.data().created.toDate(), 'dd/MM/yyyy')
        })
      })

      const lastDoc4 = snapshot.docs[snapshot.docs.length -1]; //Pegando o ultimo documento buscado
      setParceiros(parceiros => [...parceiros, ...lista4]);
      setLastDocs4(lastDoc4);
    }else{
      setIsEmpty4(true);
    }
    setLoadingMore4(false);
  }


  function togglePostModal(item){
    setShowPostModal(!showPostModal) //trocando de true pra false
    setDetail(item);
  }

  function togglePostModalImprensa(item2){
    setShowPostModal2(!showPostModal2) //trocando de true pra false
    setDetail2(item2);
  }
  
  function togglePostModalRota(item3){
    setShowPostModal3(!showPostModal3) //trocando de true pra false
    setDetail3(item3);
  }
  function togglePostModalEduca(item4){
    setShowPostModal4(!showPostModal4) //trocando de true pra false
    setDetail4(item4);
  }

  function onReady(event) {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
  }

    return(
      <div>
      <Zoom {...zoomInProperties}>
        {images.map((each, index) => (
          <div key={index} style={{width: "100%", height: "100%"}}>
            <img style={{ objectFit: "cover", width: "100%", height: "500px"}} src={each.imgbanner} />
          </div>
        ))}
      </Zoom>
<div id="posiciona"> 
      <div className="Menu">
        <div>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <img src={logo} className="App-logotop"  alt="São João del Rei" />
        </div>
        <div>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Seja bem vindo!
          <br></br>          <br></br>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <a href="#Inicio">Início</a>&nbsp;&nbsp;&nbsp;
        <a href="#MapaInterativo">Mapa Interativo</a>&nbsp;&nbsp;&nbsp;
        <a href="#Caminhos&Rotas">Caminhos & Rotas</a>&nbsp;&nbsp;&nbsp;
        <a href="#CalendárioOficial">Calendário Oficial</a>&nbsp;&nbsp;&nbsp;
        <br></br>
        </div>
      </div>
      <div className="Topo">
        <div className="App-divesquerda">
          <div style={{margin:"10px"}}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  
          </div>
        </div>
        <div className="App-divdireita">
      <div><br></br><br></br><br></br>
      <div style={{margin:"10px"}}>
            <a href={linkinstagramconfig} target="_blank"><FaInstagram color='#A5551B' font-size= 'calc(20px + 2vmin)' margin="15px"/></a><br></br>
            <a href={linkfbconfig} target="_blank"><FaFacebookSquare  color='#A5551B' font-size= 'calc(20px + 2vmin)' margin="15px" /></a><br></br>
            <a href={linklinkedconfig} target="_blank"><FaLinkedin color='#A5551B' font-size= 'calc(20px + 2vmin)' margin="15px"/></a><br></br>
            <a href={linkgoogleplayconfig} target="_blank"><FaAndroid  color='#A5551B' font-size= 'calc(20px + 2vmin)' margin="15px"/></a><br></br>
            <a href={linkapplestoreconfig} target="_blank"><FaApple color='#A5551B' font-size= 'calc(20px + 2vmin)' margin="15px"/></a><br></br>
          </div>
      <img src={patrimoniominas} className="App-patrimoniominas"  alt="Minas Gerais" /></div>
      </div>

    </div>
</div>

      <div className="InicioTitulo" id="Início">
        VISITE NOSSA CIDADE<br></br>
        Contrate um Guia!
        <br></br><br></br><br></br><br></br>
       {texto1}
      </div>
      <div className="Inicio">
       <YouTube videoId={linkvideo} opts={opts} onReady={onReady} />
      </div>

      <div className="Barra">
        <div className="Barrinha">&nbsp;&nbsp;&nbsp;</div>
      </div>

      <div className="Igr"  id="MapaInterativo">
      <div className="MioloVermelho">
          <div className="MioloEsquerda">
          MAPA INTERATIVO
          <br></br><br></br>
          {texto2}

          </div>
          <div className="MioloDireita">
            <div className="Listaigr">
              <div>
                <a onClick={ () => togglePostModalEduca('Casario') }>
                <img src={casario} className="Mapainterativoimg" alt="Casario" />
                </a>
              </div>
              <div>
                <a onClick={ () => togglePostModalEduca('Gastronomia') }>
                    <img src={gastronomia} className="Mapainterativoimg" alt="Gastronomia" />
                </a>
              </div>
              <div>
                <a onClick={ () => togglePostModalEduca('Igrejas') }>
                    <img src={igrejas} className="Mapainterativoimg" alt="Igrejas" />
                </a>
              </div>
              <div>
                <a onClick={ () => togglePostModalEduca('Natureza') }>
                    <img src={natureza} className="Mapainterativoimg" alt="Natureza" />
                </a>
              </div>
              <div>
                <a onClick={ () => togglePostModalEduca('Museus') }>
                    <img src={museus} className="Mapainterativoimg" alt="Museus" />
                </a>
              </div>
              <div>
                <a onClick={ () => togglePostModalEduca('Outros') }>
                    <img src={outros} className="Mapainterativoimg" alt="Outros" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="Barra">
        <div className="Barrinha">&nbsp;&nbsp;&nbsp;</div>
      </div>


      <div className="Igr"  id="Caminhos&Rotas">
      <div className="MioloVermelho">
          <div className="MioloEsquerda">
          CAMINHOS & ROTAS
          <br></br><br></br>
          {texto3}

          </div>
          <div className="MioloDireita">
          <ScrollView style={{ height: '65vh', width: '100%'  }}>
          {rotas.map((item3, index)=>{
              return(
              <a onClick={ () => togglePostModalRota(item3) }>
              <div className="Listaigr">
              { item3.avatarUrlrota == null ? 
                    <img src={avatar} className="Mapainterativoimg" alt={item3.nomerota} />
                    :
                    <img src={item3.avatarUrlrota} className="Mapainterativoimg" alt={item3.nomerota} />
                  }
                  {item3.nomerota}, {item3.dadoskmrota} km.
              </div>
              </a>
              
              )
          })}
          </ScrollView>
          </div>
        </div>
      </div>

      <div className="Barra">
        <div className="Barrinha">&nbsp;&nbsp;&nbsp;</div>
      </div>

      <div className="Igr"  id="CalendárioOficial">
      <div className="MioloVermelho">
          <div className="MioloEsquerda">
          CALENDÁRIO OFICIAL
          <br></br><br></br>
          {texto4}
          <ScrollView style={{ height: '65vh', width: '100%'  }}>
          {igr.map((item3, index)=>{
        return(
          <a onClick={ () => togglePostModalRota(item3) }>
          <div className="Listaigr">
          { item3.avatarUrligr == null ? 
                <img src={avatar} className="Logoigravatarimg" alt={item3.nomeigr} />
                :
                <img src={item3.avatarUrligr} className="Logoigravatarimg" alt={item3.nomeigr} />
              }
              {item3.nomeigr}      
          </div>
          </a>
          )
        })}
      </ScrollView>
          </div>
          <div className="MioloDireita">
          <div className="Listaigr">
              <div>
                <a onClick={ () => togglePostModalEduca('') }>
                <img src={eventos} className="Mapainterativoimg" alt="Calendário Eventos" />
                </a>
              </div>
              <div>
                <a onClick={ () => togglePostModalEduca('') }>
                    <img src={festas} className="Mapainterativoimg" alt="Calendário Festas" />
                </a>
              </div>
              <div>
                <a onClick={ () => togglePostModalEduca('') }>
                    <img src={religioso} className="Mapainterativoimg" alt="Calendário Religioso" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div> 

      <div className="Barra">
        <div className="Barrinha">&nbsp;&nbsp;&nbsp;</div>
      </div>

      <div className="IgrTitulo" id="Downloads">
        Instagram
      </div>
      <div className="Igr">
        <div className="MioloClaro">
          <div className="Drive">
 
          <iframe src={"//lightwidget.com/widgets/a1b016ad10f35ef68236a15d5235f3ba.html"} scrolling="no" allowtransparency="true" class="lightwidget-widget" style={{width:"100%",  border:"0", overflow:"hidden"}}></iframe>

          </div>
        </div>
      </div>


      <div className="ParceirosTitulo" id="Parceiros">
        Parceiros<br></br>
      </div>
      <div className="Parceiros">
        <div style={{height: "100%", width: "100%", display: "flex", alignContent: "center", alignSelf: "center",   textalign: "center"}}>
        {parceiros.map((itemp, index)=>{
                  return(
        <div className="Parceiroscada">
          <a href={itemp.linksiteparceiro} target="_blank">
          { itemp.avatarUrlparceiro == null ? 
                <img src={avatar} width="auto" height="60" object-fit="scale-down" alt={itemp.nomeparceiro} />
                :
                <img src={itemp.avatarUrlparceiro} width="auto" height="60" object-fit="scale-down" alt={itemp.nomeparceiro} />
              }
          </a>
        </div>
            )
          })}
        </div>
      </div>
    
      <div className="Rodape" id="Contato">
        <div style={{width: 300, textAlign: "center", verticalAlign: "center",}}>
          <img src={logo} className="App-logoinferior"  alt="São João del rei" />
        </div>
        <div style={{width: 500, textAlign: "left", verticalAlign: "center",}}>
          {logradouroconfig}, {numeroconfig}, {complementoconfig}, 
          {bairroconfig}, {cepconfig}<br></br>
          {cidadeconfig},<br></br>
          {estadoconfig}, {paisconfig}.<br></br>
          {telefone1config} {telefone2config} <br></br>
          <a mailto={emailconfig}>{emailconfig}</a><br></br>
        </div>
  
        <div style={{width: 150, textAlign: "left", margin: 5,}}>
          <a href={linkfbconfig} target="_blank" ><FaFacebookSquare color="#000" size={25}/></a>&nbsp;&nbsp;
          <a href={linkinstagramconfig} target="_blank"><FaInstagram  color="#000" size={25}/></a>&nbsp;&nbsp;
      </div>
 
      </div>
      <div className="Barrafinal">
      Todos os direitos reservados © {razaoconfig}, {cnpjconfig} <Link to="/login"><FiSettings color="#fff" size={16} /></Link>
      </div>

      
      {showPostModal && (
        <Modalcidade
          conteudo={detail}
          close={togglePostModal}
        />
      )}
      {showPostModal2 && (
        <Modalimprensa
          conteudo2={detail2}
          close={togglePostModalImprensa}
        />
      )}
      {showPostModal3 && (
        <Modalrota
          conteudo3={detail3}
          close={togglePostModalRota}
        />
      )}
      {showPostModal4 && (
        <Modaleduca
          conteudo4={detail4}
          close={togglePostModalEduca}
        />
      )}
  </div>
  )
}