
import './modal.css';

import { FaFacebookSquare, FaInstagram, FaWhatsappSquare, FaLaptop } from "react-icons/fa";
import { FiX } from 'react-icons/fi';
import { ScrollView } from "@cantonjs/react-scroll-view";

export default function Modalimprensa({conteudo2, close}){
  return(
    <div className="modal">
      <div className="container">
        <button className="close" onClick={ close }>
          <FiX size={23} color="#FFF" />
          Fechar
        </button>
        <ScrollView style={{ height: '45vh' }}>
        <div>
          <h2>{conteudo2.tituloimprensa}</h2>
          <div className="row">
            <span>
              {conteudo2.texto1imprensa}
            </span>
          </div>
        </div>

      </ScrollView>
      </div>
    </div>
  )
}