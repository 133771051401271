import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
import { FaMapMarkerAlt } from 'react-icons/fa';

const AnyReactComponent = ({ text }) => <div>{text}</div>;

class SimpleMap extends Component {
  static defaultProps = {
    center: {
      lat: -21.13,
      lng: -44.26
    },
    zoom: 14
  };

  render() {
    return (
      // Important! Always set the container height explicitly
      <div style={{ height: '70vh', width: '100%' }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key:'AIzaSyC53dwBNB78-BtSBm3-euAetfeiicTjpzM' }}
          defaultCenter={this.props.center}
          defaultZoom={this.props.zoom}
        >

          <AnyReactComponent
            lat={-21.13328}
            lng={-44.26220}
            text={<FaMapMarkerAlt color='#3256A3' font-size= 'calc(14px + 2vmin)' margin="5px"/>}
            
          />
        </GoogleMapReact>
      </div>
    );
  }
}

export default SimpleMap;