
import { useState, useEffect, useContext } from 'react';

import firebase from '../../services/firebaseConnection';
import { useHistory, useParams } from 'react-router-dom';

import Header from '../../components/Header';
import Title from '../../components/Title';
import { AuthContext } from '../../contexts/auth';
import { toast } from 'react-toastify';

import './config.css';
import { FiPlusCircle } from 'react-icons/fi'

export default function Config(){
  const { id } = useParams();
  const history = useHistory();

  const [loadConfig, setLoadConfig] = useState(true);
  const [config, setConfig] = useState([]);
  const [configSelected, setConfigSelected] = useState(0);

  const [nomeconfig, setNomeconfig] = useState('');
  const [sloganconfig, setSloganconfig]= useState('');
  const [desccurtaconfig, setDesccurtaconfig] = useState('');
  const [palavrachaveconfig, setPalavrachaveconfig] = useState('');
  const [razaoconfig, setRazaoconfig] = useState('');
  const [cnpjconfig, setCnpjconfig] = useState('');
  const [cepconfig, setCepconfig] = useState('');
  const [logradouroconfig, setLogradouroconfig] = useState('');
  const [numeroconfig, setNumeroconfig] = useState('');
  const [complementoconfig, setComplementoconfig] = useState('');
  const [bairroconfig, setBairroconfig] = useState('');
  const [cidadeconfig, setCidadeconfig] = useState('');
  const [estadoconfig, setEstadoconfig] = useState('');
  const [paisconfig, setPaisconfig] = useState('');
  const [siteconfig, setSiteconfig] = useState('');
  const [emailconfig, setEmailconfig] = useState('');
  const [linkinstagramconfig, setLinkinstagramconfig] = useState('');
  const [linkfbconfig, setLinkfbconfig] = useState('');
  const [linkyoutubeconfig, setLinkyoutubeconfig] = useState('');
  const [linkdriveconfig, setLinkdriveconfig] = useState('');
  const [linkdrivelegisconfig, setLinkdrivelegisconfig] = useState('');
  const [linkdrivetransconfig, setLinkdrivetransconfig] = useState('');
  const [linkdrivefotoconfig, setLinkdrivefotoconfig] = useState('');
  const [linklinkedconfig, setLinklinkedconfig] = useState('');
  const [linkapplestoreconfig, setLinkapplestoreconfig] = useState('');
  const [linkgoogleplayconfig, setLinkgoogleplayconfig] = useState('');
  const [telefone1config, setTelefone1config] = useState('');
  const [telefone2config, setTelefone2config] = useState('');
  const [whats1config, setWhats1config] = useState('');
  const [whats2config, setWhats2config] = useState('');

  const [idconfig, setIdconfig] = useState(false);

  const { user } = useContext(AuthContext);

  useEffect(()=> {
    async function loadConfig(){
      await firebase.firestore().collection('config')
      .get()
      .then((snapshot)=>{
        let lista = [];

        snapshot.forEach((doc) => {
          lista.push({
            id: doc.id,
            nomeconfig: doc.data().nomeconfig
          })
        })

        if(lista.length === 0){
          console.log('NENHUMA CONFIGURAÇÃO ENCONTRADA');
          setConfig([ { id: '1', nomeconfig: 'AVULSO' } ]);
          setLoadConfig(false);
          return;
        }

        setConfig(lista);
        setLoadConfig(false);

        if(id){
          loadId(lista);
        }

      })
      .catch((error)=>{
        console.log('DEU ALGUM ERRO!', error);
        setLoadConfig(false);
        setConfig([ { id: '1', nomeconfig: '' } ]);
      })

    }
    loadConfig();
  }, [id]);


  async function loadId(lista){
    await firebase.firestore().collection('config').doc(id)
    .get()
    .then((snapshot) => {
      setNomeconfig(snapshot.data().nomeconfig);
      setSloganconfig(snapshot.data().sloganconfig);
      setDesccurtaconfig(snapshot.data().desccurtaconfig);
      setPalavrachaveconfig(snapshot.data().palavrachaveconfig);
      setRazaoconfig(snapshot.data().razaoconfig);
      setCnpjconfig(snapshot.data().cnpjconfig);
      setCepconfig(snapshot.data().cepconfig);
      setLogradouroconfig(snapshot.data().logradouroconfig);
      setNumeroconfig(snapshot.data().numeroconfig);
      setComplementoconfig(snapshot.data().complementoconfig);
      setBairroconfig(snapshot.data().bairroconfig);
      setCidadeconfig(snapshot.data().cidadeconfig);
      setEstadoconfig(snapshot.data().estadoconfig);
      setPaisconfig(snapshot.data().paisconfig);
      setSiteconfig(snapshot.data().siteconfig);
      setEmailconfig(snapshot.data().emailconfig);
      setLinkinstagramconfig(snapshot.data().linkinstagramconfig);
      setLinkfbconfig(snapshot.data().linkfbconfig);
      setLinkyoutubeconfig(snapshot.data().linkyoutubeconfig);
      setLinkdriveconfig(snapshot.data().linkdriveconfig);
      setLinkdrivelegisconfig(snapshot.data().linkdrivelegisconfig);
      setLinkdrivetransconfig(snapshot.data().linkdrivetransconfig);
      setLinkdrivefotoconfig(snapshot.data().linkdrivefotoconfig);
      setLinklinkedconfig(snapshot.data().linklinkedconfig);
      setLinkapplestoreconfig(snapshot.data().linkapplestoreconfig);
      setLinkgoogleplayconfig(snapshot.data().linkgoogleplayconfig);
      setTelefone1config(snapshot.data().telefone1config);
      setTelefone2config(snapshot.data().telefone2config);
      setWhats1config(snapshot.data().whats1config);
      setWhats2config(snapshot.data().whats2config)

      let index = lista.findIndex(item => item.id === snapshot.data().configId );
      setConfigSelected(index);
      setIdconfig(true);

    })
    .catch((err)=>{
      console.log('ERRO NO ID PASSADO: ', err);
      setIdconfig(false);
    })
  }

  async function handleRegister(e){
    e.preventDefault();

    if(idconfig){
      await firebase.firestore().collection('config')
      .doc(id)
      .update({
        nomeconfig: nomeconfig,
        sloganconfig: sloganconfig,
        desccurtaconfig: desccurtaconfig,
        palavrachaveconfig: palavrachaveconfig,
        razaoconfig: razaoconfig,
        cnpjconfig: cnpjconfig,
        cepconfig: cepconfig,
        logradouroconfig: logradouroconfig,
        numeroconfig: numeroconfig,
        complementoconfig: complementoconfig,
        bairroconfig: bairroconfig,
        cidadeconfig: cidadeconfig,
        estadoconfig: estadoconfig,
        paisconfig: paisconfig,
        siteconfig: siteconfig,
        emailconfig: emailconfig,
        linkinstagramconfig: linkinstagramconfig,
        linkfbconfig: linkfbconfig,
        linkyoutubeconfig: linkyoutubeconfig,
        linkdriveconfig: linkdriveconfig,
        linkdrivelegisconfig: linkdrivelegisconfig,
        linkdrivetransconfig: linkdrivetransconfig,
        linkdrivefotoconfig: linkdrivefotoconfig,
        linklinkedconfig: linklinkedconfig,
        linkapplestoreconfig: linkapplestoreconfig,
        linkgoogleplayconfig: linkgoogleplayconfig,
        telefone1config: telefone1config,
        telefone2config: telefone2config,
        whats1config: whats1config,
        whats2config: whats2config,
        userId: user.uid
      })
      .then(()=>{
        toast.success('config editado com sucesso!');
        setConfigSelected(0);
        setNomeconfig('');
        history.push('/Configlistar');
      })
      .catch((err)=>{
        toast.error('Ops erro ao registrar, tente mais tarde.')
        console.log(err);
      })

      return;
    }

    await firebase.firestore().collection('config')
    .add({
      created: new Date(),
      nomeconfig: nomeconfig,
      sloganconfig: sloganconfig,
      desccurtaconfig: desccurtaconfig,
      palavrachaveconfig: palavrachaveconfig,
      razaoconfig: razaoconfig,
      cnpjconfig: cnpjconfig,
      cepconfig: cepconfig,
      logradouroconfig: logradouroconfig,
      numeroconfig: numeroconfig,
      complementoconfig: complementoconfig,
      bairroconfig: bairroconfig,
      cidadeconfig: cidadeconfig,
      estadoconfig: estadoconfig,
      paisconfig: paisconfig,
      siteconfig: siteconfig,
      emailconfig: emailconfig,
      linkinstagramconfig: linkinstagramconfig,
      linkfbconfig: linkfbconfig,
      linkyoutubeconfig: linkyoutubeconfig,
      linkdriveconfig: linkdriveconfig,
      linkdrivelegisconfig: linkdrivelegisconfig,
      linkdrivetransconfig: linkdrivetransconfig,
      linkdrivefotoconfig: linkdrivefotoconfig,
      linklinkedconfig: linklinkedconfig,
      linkapplestoreconfig: linkapplestoreconfig,
      linkgoogleplayconfig: linkgoogleplayconfig,
      telefone1config: telefone1config,
      telefone2config: telefone2config,
      whats1config: whats1config,
      whats2config: whats2config,
      userId: user.uid
    })
    .then(()=> {
      toast.success('Configuração cadastrada com sucesso!');
      setNomeconfig('');
      setLinkfbconfig('');
      setLinkinstagramconfig('');
      setSiteconfig('');
      setConfigSelected(0);
    })
    .catch((err)=> {
      toast.error('Ops erro ao registrar, tente mais tarde.')
      console.log(err);
    })


  }


  return(
    <div>
      <Header/>

      <div className="content">
        <Title name="Config">
          <FiPlusCircle size={25} />
        </Title>

        <div className="container">

          <form className="form-profile"  onSubmit={handleRegister} >
            <label>Config</label>
            <input type="text" placeholder="Nome da config" value={nomeconfig} onChange={ (e) => setNomeconfig(e.target.value) } />
            <label>Slogan</label>
            <input type="text" placeholder="slogan" value={sloganconfig} onChange={ (e) => setSloganconfig(e.target.value) } />

            <label>Descrição Curta</label>
            <textarea
              type="text"
              placeholder="Descrição curta"
              value={desccurtaconfig}
              onChange={ (e) => setDesccurtaconfig(e.target.value) }
            />
            <label>Palavras chaves, por importância e separadas por virgula.</label>
            <textarea
              type="text"
              placeholder="turismo, cultura, atrativos, etc"
              value={palavrachaveconfig}
              onChange={ (e) => setPalavrachaveconfig(e.target.value) }
            />
            <label>Razão Social</label>
            <input type="text" placeholder="razão social" value={razaoconfig} onChange={ (e) => setRazaoconfig(e.target.value) } />
            <label>CNPJ</label>
            <input type="text" placeholder="XXXXXXXX/XXXX-XX" value={cnpjconfig} onChange={ (e) => setCnpjconfig(e.target.value) } />
            <label>CEP</label>
            <input type="text" placeholder="XXXXX-XXX" value={cepconfig} onChange={ (e) => setCepconfig(e.target.value) } />
            <label>Logradouro</label>
            <input type="text" placeholder="logradouro" value={logradouroconfig} onChange={ (e) => setLogradouroconfig(e.target.value) } />
            <label>Número</label>
            <input type="text" placeholder="número" value={numeroconfig} onChange={ (e) => setNumeroconfig(e.target.value) } />
            <label>Complemento</label>
            <input type="text" placeholder="complemento" value={complementoconfig} onChange={ (e) => setComplementoconfig(e.target.value) } />
            <label>Bairro</label>
            <input type="text" placeholder="bairro" value={bairroconfig} onChange={ (e) => setBairroconfig(e.target.value) } />
            <label>Cidade</label>
            <input type="text" placeholder="cidade" value={cidadeconfig} onChange={ (e) => setCidadeconfig(e.target.value) } />
            <label>Estado</label>
            <input type="text" placeholder="Minas Gerais" value={estadoconfig} onChange={ (e) => setEstadoconfig(e.target.value) } />
            <label>País</label>
            <input type="text" placeholder="Brasil" value={paisconfig} onChange={ (e) => setPaisconfig(e.target.value) } />

            <label>Site</label>
            <input type="text" placeholder="link sem o http://" value={siteconfig} onChange={ (e) => setSiteconfig(e.target.value) } />
            <label>E-mail</label>
            <input type="text" placeholder="Nome da config" value={emailconfig} onChange={ (e) => setEmailconfig(e.target.value) } />

            <label>Facebook</label>
            <input type="text" placeholder="link completo" value={linkfbconfig} onChange={ (e) => setLinkfbconfig(e.target.value) } />
            <label>Instagram</label>
            <input type="text" placeholder="link completo" value={linkinstagramconfig} onChange={ (e) => setLinkinstagramconfig(e.target.value) } />
            <label>You Tube</label>
            <input type="text" placeholder="link completo" value={linkyoutubeconfig} onChange={ (e) => setLinkyoutubeconfig(e.target.value) } />
            <label>Linkedin</label>
            <input type="text" placeholder="link completo" value={linklinkedconfig} onChange={ (e) => setLinklinkedconfig(e.target.value) } />
            <label>Apple Store</label>
            <input type="text" placeholder="link completo" value={linkapplestoreconfig} onChange={ (e) => setLinkapplestoreconfig(e.target.value) } />
            <label>Google Play Store</label>
            <input type="text" placeholder="link completo" value={linkgoogleplayconfig} onChange={ (e) => setLinkgoogleplayconfig(e.target.value) } />

            <label>Telefone 1</label>
            <input type="text" placeholder="(XX)XXXXX-XXXX" value={telefone1config} onChange={ (e) => setTelefone1config(e.target.value) } />
            <label>Telefone 2</label>
            <input type="text" placeholder="(XX)XXXXX-XXXX" value={telefone2config} onChange={ (e) => setTelefone2config(e.target.value) } />
            <label>WhatsApp 1</label>
            <input type="text" placeholder="+55(XX)XXXXX-XXXX" value={whats1config} onChange={ (e) => setWhats1config(e.target.value) } />
            <label>WhatsApp 2</label>
            <input type="text" placeholder="+55(XX)XXXXX-XXXX" value={whats2config} onChange={ (e) => setWhats2config(e.target.value) } />

            <button type="submit">Registrar</button>

          </form>

        </div>

      </div>
    </div>
  )
}