
import { useState, useEffect, useContext } from 'react';

import firebase from '../../services/firebaseConnection';
import { useHistory, useParams } from 'react-router-dom';

import Header from '../../components/Header';
import Title from '../../components/Title';
import avatar from '../imagens/img.png';
import { AuthContext } from '../../contexts/auth';
import { toast } from 'react-toastify';

import './rotas.css';
import { FiPlusCircle, FiUpload } from 'react-icons/fi'

export default function Textos(){
  const { id } = useParams();
  const history = useHistory();
  const { user } = useContext(AuthContext);

  const [loadRotas, setLoadRotas] = useState(true);
  const [rotas, setRotas] = useState([]);
  const [rotasSelected, setRotasSelected] = useState(0);

  const [nomerota, setNomerota] = useState('');
  const [cidadesrota, setCidadesrota] = useState('');
  const [estadorota, setEstadorota] = useState('');
  const [textosimplesrota, setTextosimplesrota] = useState('');
  const [textocompletorota, setTextocompletorota] = useState('');

  const [dadoskmrota, setDadoskmrota] = useState('');

  const [midiagpxkmlrota, setMidiagpxkmlrota] = useState('');

  const [idRotas, setIdRotas] = useState(false);

  const [avatarUrlrota, setAvatarUrlrota] = useState(null);
  const [avatarUrlrotar, setAvatarUrlrotar] = useState(null);
  const [imageAvatar, setImageAvatar] = useState(null);

  const [uploading, setUploading] = useState(false);

  useEffect(()=> {
    async function loadRotas(){
      await firebase.firestore().collection('rotas')
      .get()
      .then((snapshot)=>{
        let lista = [];

        snapshot.forEach((doc) => {
          lista.push({
            id: doc.id,
            nomerota: doc.data().nomerota
          })
        })

        if(lista.length === 0){
          console.log('NENHUMA CIDADE ENCONTRADA');
          setRotas([ { id: '1', nomerota: 'AVULSO' } ]);
          setLoadRotas(false);
          return;
        }

        setRotas(lista);
        setLoadRotas(false);

        if(id){
          loadId(lista);
        }

      })
      .catch((error)=>{
        console.log('DEU ALGUM ERRO!', error);
        setLoadRotas(false);
        setRotas([ { id: '1', nomerota: '' } ]);
      })

    }
    loadRotas();
  }, [id]);


  async function loadId(lista){
    await firebase.firestore().collection('rotas').doc(id)
    .get()
    .then((snapshot) => {
      setAvatarUrlrota(snapshot.data().avatarUrlrota);
      setNomerota(snapshot.data().nomerota);
      setCidadesrota(snapshot.data().cidadesrota);
      setEstadorota(snapshot.data().estadorota);
      setTextosimplesrota(snapshot.data().textosimplesrota);
      setTextocompletorota(snapshot.data().textocompletorota);
      setDadoskmrota(snapshot.data().dadoskmrota);
      setMidiagpxkmlrota(snapshot.data().midiagpxkmlrota)

      let index = lista.findIndex(item => item.id === snapshot.data().projetoId );
      setRotasSelected(index);
      setIdRotas(true);

    })
    .catch((err)=>{
      console.log('ERRO NO ID PASSADO: ', err);
      setIdRotas(false);
    })
  }

  function handleFile(e){
    if(e.target.files[0]){
      const image = e.target.files[0];
      
      if(image.type === 'image/jpeg' || image.type === 'image/png'){

        setImageAvatar(image);
        setAvatarUrlrota(URL.createObjectURL(e.target.files[0]))

      }else{
        alert('Envie uma imagem do tipo PNG ou JPEG');
        setImageAvatar(null);
        return null;
      }
    }
  }

  async function handleUpload(){
    toast.success('Envio Iniciado!');
    if (imageAvatar){
    const uploadTask = await firebase.storage()
    .ref(`images/rotas/${id}/avatar/Avatar`)
    .put(imageAvatar)
    .then( async () => {
      toast.success('Envio Completo!');
      await firebase.storage().ref(`images/rotas/${id}/avatar/`)
      .child("Avatar").getDownloadURL()
      .then( async (url)=>{
        let urlFoto = url;
        toast.success(`enviou corrreto ${urlFoto}`);
        
        await firebase.firestore().collection('rotas')
        .doc(id)
        .update({
          avatarUrlrota: urlFoto,
          nomerota: nomerota,
          cidadesrota: cidadesrota,
          estadorota: estadorota,
          textosimplesrota: textosimplesrota,
          textocompletorota: textocompletorota,
          dadoskmrota: dadoskmrota,
          midiagpxkmlrota: midiagpxkmlrota,
          userId: user.uid
        })
        .then(()=>{
          toast.success(`Foto Enviada e Rota editado com sucesso! ${id}`);
          setRotasSelected(0);
          setNomerota('');
          history.push('/rotaslistar');
        })
      })
    })
      }
      else {
      alert('Upload failed, sorry :( 39');
    } 
  };


  async function handleRegister(e){
    e.preventDefault();

    if(idRotas === true && imageAvatar === null){
      await firebase.firestore().collection('rotas')
      .doc(id)
      .update({
        nomerota: nomerota,
        cidadesrota: cidadesrota,
        estadorota: estadorota,
        textosimplesrota: textosimplesrota,
        textocompletorota: textocompletorota,
        dadoskmrota: dadoskmrota,
        midiagpxkmlrota: midiagpxkmlrota,
        userId: user.uid
      })
      .then(()=>{
        toast.success('Rota editada com sucesso!');
        setRotasSelected(0);
        setNomerota('');
        history.push('/rotaslistar');
      })
      .catch((err)=>{
        toast.error('Ops erro ao registrar, tente mais tarde.')
        console.log(err);
      })

    }
    else if (idRotas === true && imageAvatar !== null){
      handleUpload();
    }

    else if(idRotas === false){

    await firebase.firestore().collection('rotas')
    .add({
      created: new Date(),
      nomerota: nomerota,
      cidadesrota: cidadesrota,
      estadorota: estadorota,
      textosimplesrota: textosimplesrota,
      textocompletorota: textocompletorota,
      dadoskmrota: dadoskmrota,
      midiagpxkmlrota: midiagpxkmlrota,
      userId: user.uid
    })
    .then(()=> {
      toast.success('Rota cadastrada com sucesso!');
      setNomerota('');
      setCidadesrota('');
      setEstadorota('');
      setTextosimplesrota('');
      setTextocompletorota('');

      setRotasSelected(0);
      history.push('/rotaslistar');
    })
    .catch((err)=> {
      toast.error('Ops erro ao registrar, tente mais tarde.')
      console.log(err);
    })
  }

  }


  //Chamado quando troca o assunto
  function handleChangeEstado(e){
    setEstadorota(e.target.value);
  }


  return(
    <div>
      <Header/>

      <div className="content">
        <Title name="Nova Rota">
          <FiPlusCircle size={25} />
        </Title>

        <div className="container">

          <form className="form-profile"  onSubmit={handleRegister} >
            <label>Rota</label>
            <input type="text" placeholder="Nome da rota" value={nomerota} onChange={ (e) => setNomerota(e.target.value) } />

            <label>Estado</label>
            <select value={estadorota} onChange={handleChangeEstado}>
              <option value="MG">Mina Gerais</option>
            </select>

            <label>Cidades</label>
            <input type="text" placeholder="cidades separadas por virgula" value={cidadesrota} onChange={ (e) => setCidadesrota(e.target.value) } />
            <label>
              <i>Imagens: prefira imagens entre 1000 x 1000 pixel</i>
            </label>
            { idRotas === true ?
            <label className="label-avatar">
              <span>
                <FiUpload color="#FFF" size={25} />
              </span>

              <input type="file" accept="image/*" onChange={handleFile}  /><br/>
              { avatarUrlrota == null ? 
                <img src={avatar} width="250" height="250" alt="Sem Foto Rota" />
                :
                <img src={avatarUrlrota} width="250" height="250" alt="Foto Rota" />
              }
            </label>
            :
            <label className="label-avatar"> Salve para colocar foto
              <span>
                <FiUpload color="#000" size={25} />
              </span>
            </label>
          }
            <label>Texto Simples - 100 caracteres</label>
            <textarea
              type="text"
              placeholder="texto simples"
              value={textosimplesrota}
              onChange={ (e) => setTextosimplesrota(e.target.value) }
            />

            <label>Texto Completo - 500 caracteres</label>
            <textarea
              type="text"
              placeholder="texto completo"
              value={textocompletorota}
              onChange={ (e) => setTextocompletorota(e.target.value) }
            />

            <br></br>
            <label>Dados do rota</label>
            <label>Km Total</label>
            <input type="text" placeholder="Km total" value={dadoskmrota} onChange={ (e) => setDadoskmrota(e.target.value) } />

            <br></br>
            <label>Arquivos</label>  
            <label>Arquivo GPX - KML</label>
            <input type="text" placeholder="link GPX - KML" value={midiagpxkmlrota} onChange={ (e) => setMidiagpxkmlrota(e.target.value) } />
            <br></br>
            <br></br>

            <button type="submit">Registrar</button>

          </form>

        </div>

      </div>
    </div>
  )
}