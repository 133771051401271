
import { useState, useEffect, useContext } from 'react';

import firebase from '../../services/firebaseConnection';
import { useHistory, useParams } from 'react-router-dom';

import Header from '../../components/Header';
import Title from '../../components/Title';
import avatar from '../imagens/img.png';
import { AuthContext } from '../../contexts/auth';
import { toast } from 'react-toastify';

import './calendario.css';
import { FiPlusCircle, FiUpload } from 'react-icons/fi'

export default function Textos(){
  const { id } = useParams();
  const history = useHistory();
  const { user } = useContext(AuthContext);

  const [loadcidades, setLoadcidades] = useState(true);
  const [cidades, setCidades] = useState([]);
  const [cidadesSelected, setCidadesSelected] = useState(0);

  const [nomeevento, setNomeevento] = useState('');
  const [dataevento, setDataevento] = useState('')
  const [cidadeevento, setCidadeevento] = useState('São João del Rei');
  const [estadoevento, setEstadoevento] = useState('MG');
  const [tipocalendario, setTipocalendario] = useState('Festas Católicas');
  const [textosimplesevento, setTextosimplesevento] = useState('');
  const [textocompletoevento, setTextocompletoevento] = useState('');
  const [linkfbevento, setLinkfbevento] = useState('');
  const [linkinstagramevento, setLinkinstagramevento] = useState('');
  const [linksiteevento, setLinksiteevento] = useState('');

  const [idEventos, setIdEventos] = useState(false);
  const [eventosSelected, setEventosSelected] = useState(0);

  const [avatarUrlevento, setAvatarUrlevento] = useState(null);
  const [avatarUrleventor, setAvatarUrleventor] = useState(null);
  const [imageAvatar, setImageAvatar] = useState(null);

  const [uploading, setUploading] = useState(false);

  useEffect(()=> {
    async function loadcidades(){
      await firebase.firestore().collection('cidades')
      .get()
      .then((snapshot)=>{
        let lista = [];
        snapshot.forEach((doc) => {
          lista.push({
            id: doc.id,
            nomecidade: doc.data().nomecidade
          })
        })

        if(lista.length === 0){
          console.log('NENHUMA CIDADE ENCONTRADA');
          setCidades([ { id: '1', nomecidade: 'AVULSO' } ]);
          setLoadcidades(false);
          return;
        }
        setCidades(lista);
        setLoadcidades(false);
        if(id){
          loadId(lista);
        }
      })
      .catch((error)=>{
        console.log('DEU ALGUM ERRO!', error);
        setLoadcidades(false);
        setCidades([ { id: '1', nomecidade: '' } ]);
      })
    }
    loadcidades();
  }, [id]);



  async function loadId(lista){
    await firebase.firestore().collection('eventos').doc(id)
    .get()
    .then((snapshot) => {
      setAvatarUrlevento(snapshot.data().avatarUrlevento);
      setNomeevento(snapshot.data().nomeevento);
      setDataevento(snapshot.data().dataevento);
      setCidadeevento(snapshot.data().cidadeevento);
      setEstadoevento(snapshot.data().estadoevento);
      setTipocalendario(snapshot.data().tipocalendario);
      setTextosimplesevento(snapshot.data().textosimplesevento);
      setTextocompletoevento(snapshot.data().textocompletoevento);
      setLinkfbevento(snapshot.data().linkfbevento);
      setLinkinstagramevento(snapshot.data().linkinstagramevento);
      setLinksiteevento(snapshot.data().linksiteevento)

      let index = lista.findIndex(item => item.id === snapshot.data().projetoId );
      setEventosSelected(index);
      setIdEventos(true);

    })
    .catch((err)=>{
      console.log('ERRO NO ID PASSADO: ', err);
      setIdEventos(false);
    })
  }

  function handleFile(e){
    if(e.target.files[0]){
      const image = e.target.files[0];
      
      if(image.type === 'image/jpeg' || image.type === 'image/png'){

        setImageAvatar(image);
        setAvatarUrlevento(URL.createObjectURL(e.target.files[0]))

      }else{
        alert('Envie uma imagem do tipo PNG ou JPEG');
        setImageAvatar(null);
        return null;
      }
    }
  }

  async function handleUpload(){
    toast.success('Envio Iniciado!');
    if (imageAvatar){
    const uploadTask = await firebase.storage()
    .ref(`images/eventos/${id}/avatar/Avatar`)
    .put(imageAvatar)
    .then( async () => {
      toast.success('Envio Completo!');
      await firebase.storage().ref(`images/eventos/${id}/avatar/`)
      .child("Avatar").getDownloadURL()
      .then( async (url)=>{
        let urlFoto = url;
        toast.success(`enviou corrreto ${urlFoto}`);
        
        await firebase.firestore().collection('eventos')
        .doc(id)
        .update({
          avatarUrlevento: urlFoto,
          nomeevento: nomeevento,
          dataevento: dataevento,
          tipocalendario: tipocalendario,
          cidadeevento: cidadeevento,
          estadoevento: estadoevento,
          textosimplesevento: textosimplesevento,
          textocompletoevento: textocompletoevento,
          linkfbevento: linkfbevento,
          linkinstagramevento: linkinstagramevento,
          linksiteevento: linksiteevento,
          userId: user.uid
        })
        .then(()=>{
          toast.success(`Foto Enviada e Evento editado com sucesso! ${id}`);
          setEventosSelected(0);
          setNomeevento('');
          history.push('/calendariolistar');
        })
      })
    })
      }
      else {
      alert('Upload failed, sorry :( 39');
    } 
  };


  async function handleRegister(e){
    e.preventDefault();

    if(idEventos === true && imageAvatar === null){
      await firebase.firestore().collection('eventos')
      .doc(id)
      .update({
        nomeevento: nomeevento,
        dataevento: dataevento,
        tipocalendario: tipocalendario,
        cidadeevento: cidadeevento,
        estadoevento: estadoevento,
        textosimplesevento: textosimplesevento,
        textocompletoevento: textocompletoevento,
        linkfbevento: linkfbevento,
        linkinstagramevento: linkinstagramevento,
        linksiteevento: linksiteevento,
        userId: user.uid
      })
      .then(()=>{
        toast.success('Evento editada com sucesso!');
        setEventosSelected(0);
        setNomeevento('');
        history.push('/calendariolistar');
      })
      .catch((err)=>{
        toast.error('Ops erro ao registrar, tente mais tarde.')
        console.log(err);
      })

    }
    else if (idEventos === true && imageAvatar !== null){
      handleUpload();
    }

    else if(idEventos === false){

    await firebase.firestore().collection('eventos')
    .add({
      created: new Date(),
      nomeevento: nomeevento,
      dataevento: dataevento,
      tipocalendario: tipocalendario,
      cidadeevento: cidadeevento,
      estadoevento: estadoevento,
      textosimplesevento: textosimplesevento,
      textocompletoevento: textocompletoevento,
      linkfbevento: linkfbevento,
      linkinstagramevento: linkinstagramevento,
      linksiteevento: linksiteevento,
      userId: user.uid
    })
    .then(()=> {
      toast.success('Evento cadastrada com sucesso!');
      setNomeevento('');
      setDataevento('');
      setTipocalendario('');
      setEstadoevento('');
      setCidadeevento('');
      setTextosimplesevento('');
      setTextocompletoevento('');
      setLinkfbevento('');
      setLinkinstagramevento('');
      setLinksiteevento('');
      setEventosSelected(0);
      history.push('/calendariolistar');
    })
    .catch((err)=> {
      toast.error('Ops erro ao registrar, tente mais tarde.')
      console.log(err);
    })
  }

  }

 //Chamado quando troca a evento
 function handleChangeCidade(e){
  setCidadeevento(e.target.value);
}
  //Chamado quando troca o estado
  function handleChangeEstado(e){
    setEstadoevento(e.target.value);
  }
  //Chamado quando troca o calendario
  function handleChangeCalendario(e){
    setTipocalendario(e.target.value);
  }

  return(
    <div>
      <Header/>

      <div className="content">
        <Title name="Eventos no Calendário">
          <FiPlusCircle size={25} />
        </Title>

        <div className="container">

          <form className="form-profile"  onSubmit={handleRegister} >
            <label>Data xx/xx/xxxx</label>
            <input type="text" placeholder="Data xx/xx/xxxx" value={dataevento} onChange={ (e) => setDataevento(e.target.value) } />
            <label>Nome Evento</label>
            <input type="text" placeholder="Nome" value={nomeevento} onChange={ (e) => setNomeevento(e.target.value) } />

            <label>Cidade</label>
            {loadcidades ? (
              <input type="text" disabled={true} value="Carregando cidades..." />
            ) : (
                <select value={cidadesSelected} onChange={handleChangeCidade} >
                {cidades.map((item, index) => {
                  return(
                    <option key={item.id} value={index} >
                      {item.nomecidade}
                    </option>
                  )
                })}
              </select>
            )}


            <label>Calendário</label>
            <select value={tipocalendario} onChange={handleChangeCalendario}>
              <option value="Festas Católicas">Festas Católicas</option>
              <option value="Festas de Terreiro">Festas de Terreiro</option>
              <option value="Festas Evangélicas">Festas Evangélicas</option>
              <option value="Feriados Nacionais">Feriados Nacionais</option>
            </select>
            { idEventos === true ?
            <label className="label-avatar">
              <span>
                <FiUpload color="#FFF" size={25} />
              </span>

              <input type="file" accept="image/*" onChange={handleFile}  /><br/>
              { avatarUrlevento == null ? 
                <img src={avatar} width="250" height="250" alt="Sem Foto Evento" />
                :
                <img src={avatarUrlevento} width="250" height="250" alt="Foto Evento" />
              }
            </label>
            :
            <label className="label-avatar">Complete o registro de texto liberar a colocação de foto.
              <span>
                <FiUpload color="#000" size={25} />
              </span>
            </label>
          }
            <label>Texto Simples - 100 caracteres</label>
            <textarea
              type="text"
              placeholder="texto simples"
              value={textosimplesevento}
              onChange={ (e) => setTextosimplesevento(e.target.value) }
            />

            <label>Texto Completo - 500 caracteres</label>
            <textarea
              type="text"
              placeholder="texto completo"
              value={textocompletoevento}
              onChange={ (e) => setTextocompletoevento(e.target.value) }
            />

            <label>Facebook</label>
            <input type="text" placeholder="link Facebook" value={linkfbevento} onChange={ (e) => setLinkfbevento(e.target.value) } />
            <label>Instagram</label>
            <input type="text" placeholder="link Instagram" value={linkinstagramevento} onChange={ (e) => setLinkinstagramevento(e.target.value) } />
            <label>Página</label>
            <input type="text" placeholder="link sem o http://" value={linksiteevento} onChange={ (e) => setLinksiteevento(e.target.value) } />
            
            <button type="submit">Registrar</button>

          </form>

        </div>

      </div>
    </div>
  )
}