
import './modal.css';
import { FaFacebookSquare, FaInstagram, FaWhatsappSquare, FaLaptop } from "react-icons/fa";
import { FiX } from 'react-icons/fi';
import React, { Component } from "react";
import { ScrollView } from "@cantonjs/react-scroll-view";

import avatar from '../../pages/imagens/img.png';

export default function Modaligr({conteudo3, close}){
  return(
    <div className="modal">
      <div className="container">
        <div className='row'>
          <button className="close" onClick={ close }>
            <FiX size={23} color="#FFF" />
            Fechar
          </button>
          <h2>Rota:{conteudo3.nomerota}</h2>
        </div>
        <div className='row'>
      <ScrollView style={{ height: '45vh' }}>
          <div> 
              { conteudo3.avatarUrlrota == null ? 
                <img src={avatar} className="Logorotaimg" alt={conteudo3.nomerota} />
                :
                <img src={conteudo3.avatarUrlrota} className="Logorotaimg" alt={conteudo3.nomerota} />
              }
          </div>
          <div> 
            <span>
              {conteudo3.textosimplesrota}
            </span>
          </div>
          <br></br>
          <div> 
            <span>
              Cidades: {conteudo3.cidadesrota}
            </span>
          </div>
          <br></br>
          <div> 
            <span>
              {conteudo3.textocompletorota}
            </span>
          </div>
          <br></br>
          </ScrollView>
      </div>
    </div>
  </div>
  )
}