import { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { AuthContext } from '../contexts/auth';
import { useHistory, useParams } from 'react-router-dom';

export default function RouteWrapper({
  component: Component,
  isPrivate,
  ...rest
}){
  const { signed, loading } = useContext(AuthContext);
  const { idteste } = useParams();

  if(loading){
    return(
      <div></div>
    )
  }

  if(!signed && isPrivate){
    if(!idteste){
      return <Redirect to="/:idteste" />
    }
    else{
      return <Redirect to="/" />
    }

  }

  if(signed && !isPrivate){
    return <Redirect to="/dashboard" />
  }


  return(
    <Route
      {...rest}
      render={ props => (
        <Component {...props} />
      )}
    />
  )
}