import './site.css';
import { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Header from '../../components/Header';
import Title from '../../components/Title';
import { FiMessageSquare, FiPlus, FiSearch, FiEdit2, FiSettings } from 'react-icons/fi';
import { FaLinkedin, FaFacebookSquare, FaInstagram, FaWhatsappSquare, FaLaptop, FaYoutube, FaApple, FaAndroid } from "react-icons/fa";
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import { ScrollView } from "@cantonjs/react-scroll-view";
import { Zoom } from 'react-slideshow-image';
import YouTube from 'react-youtube';

import firebase from '../../services/firebaseConnection';
import Modalcidade from '../../components/Modalcidade';
import Modalimprensa from '../../components/Modalimprensa';
import Modalrota from '../../components/Modalrota';
import Modaleduca from '../../components/Modaleduca';

import logo from '../imagens/logo.png';
import avatar from '../imagens/img.png';

import casario from '../imagens/Casario.png';
import gastronomia from '../imagens/Gastronomia.png';
import igrejas from '../imagens/Igrejas.png';
import natureza from '../imagens/Natureza.png';
import museus from '../imagens/Museus.png';
import outros from '../imagens/Outros.png';

import patrimoniominas from '../imagens/patrimoniominas.png';
import imgtiradentes from '../imagens/imgtiradentes.png';

import imgmapa from '../imagens/mapa.png';
import imgvideo from '../imagens/video.png';

import Maps1 from '../../components/Mapsigr';



const zoomInProperties = {
  indicators: true,
  scale: 1.4,
  backgroundColor: '#000'
};

const opts = {
  height: '450',
  width: '800',
  playerVars: {
    // https://developers.google.com/youtube/player_parameters
    autoplay: 0,
  },
};

export default function Dashboard(){
  const { id2 } = useParams();
  const history = useHistory();

  const [loadcidades, setLoadcidades] = useState(true);
  const [cidades, setCidades] = useState([]);
  const [cidadesSelected, setCidadesSelected] = useState(0);

  const [nomeponto, setNomeponto] = useState('');
  const [cidadeponto, setCidadeponto] = useState('');
  const [categoriaponto, setCategoriaponto] = useState('');
  const [textosimplesponto, setTextosimplesponto] = useState('');
  const [logradouroponto, setLogradouroponto] = useState('');
  const [numeroponto, setNumeroponto] = useState('');
  const [complementoponto, setComplementoponto] = useState('');
  const [gpsponto, setGpsponto] = useState('');
  const [linkvideoponto, setLinkvideoponto] = useState('');

  const [idcidades, setIdcidades] = useState(false);


  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);
  const [lastDocs, setLastDocs] = useState();
 
  const [loading2, setLoading2] = useState(true);
  const [loadingMore2, setLoadingMore2] = useState(false);
  const [isEmpty2, setIsEmpty2] = useState(false);
  const [lastDocs2, setLastDocs2] = useState();

  const [loading3, setLoading3] = useState(true);
  const [loadingMore3, setLoadingMore3] = useState(false);
  const [isEmpty3, setIsEmpty3] = useState(false);
  const [lastDocs3, setLastDocs3] = useState();

  const [loading4, setLoading4] = useState(true);
  const [loadingMore4, setLoadingMore4] = useState(false);
  const [isEmpty4, setIsEmpty4] = useState(false);
  const [lastDocs4, setLastDocs4] = useState();

  const [loading5, setLoading5] = useState(true);
  const [loadingMore5, setLoadingMore5] = useState(false);
  const [isEmpty5, setIsEmpty5] = useState(false);
  const [lastDocs5, setLastDocs5] = useState();

  const [loading6, setLoading6] = useState(true);
  const [loadingMore6, setLoadingMore6] = useState(false);
  const [isEmpty6, setIsEmpty6] = useState(false);
  const [lastDocs6, setLastDocs6] = useState();

  const [complemento, setComplemento] = useState();
  const [texto1, setTexto1] = useState();
  const [texto2, setTexto2] = useState();
  const [texto3, setTexto3] = useState();
  const [texto4, setTexto4] = useState();
  const [linkvideo, setLinkvideo] = useState();
  const [texto5, setTexto5] = useState();
  const [texto6, setTexto6] = useState();

  const [nomeconfig, setNomeconfig] = useState('');
  const [sloganconfig, setSloganconfig]= useState('');
  const [desccurtaconfig, setDesccurtaconfig] = useState('');
  const [palavrachaveconfig, setPalavrachaveconfig] = useState('');
  const [razaoconfig, setRazaoconfig] = useState('');
  const [cnpjconfig, setCnpjconfig] = useState('');
  const [cepconfig, setCepconfig] = useState('');
  const [logradouroconfig, setLogradouroconfig] = useState('');
  const [numeroconfig, setNumeroconfig] = useState('');
  const [complementoconfig, setComplementoconfig] = useState('');
  const [bairroconfig, setBairroconfig] = useState('');
  const [cidadeconfig, setCidadeconfig] = useState('');
  const [estadoconfig, setEstadoconfig] = useState('');
  const [paisconfig, setPaisconfig] = useState('');
  const [siteconfig, setSiteconfig] = useState('');
  const [emailconfig, setEmailconfig] = useState('');
  const [linkinstagramconfig, setLinkinstagramconfig] = useState('');
  const [linkfbconfig, setLinkfbconfig] = useState('');
  const [linkyoutubeconfig, setLinkyoutubeconfig] = useState('');
  const [linkdriveconfig, setLinkdriveconfig] = useState('');
  const [linkdrivelegisconfig, setLinkdrivelegisconfig] = useState('');
  const [linkdrivetransconfig, setLinkdrivetransconfig] = useState('');
  const [linkdrivefotoconfig, setLinkdrivefotoconfig] = useState('');
  const [linklinkedconfig, setLinklinkedconfig] = useState('');
  const [linkapplestoreconfig, setLinkapplestoreconfig] = useState('');
  const [linkgoogleplayconfig, setLinkgoogleplayconfig] = useState('');
  const [telefone1config, setTelefone1config] = useState('');
  const [telefone2config, setTelefone2config] = useState('');
  const [whats1config, setWhats1config] = useState('');
  const [whats2config, setWhats2config] = useState('');

  const [pontos, setPontos] = useState([]);
  const [rotas, setRotas] = useState([]);
  const [parceiros, setParceiros] = useState([]);
  const [imprensa, setImprensa] = useState([]);
  const [igr, setIgr] = useState([]);

  const [showPostModal, setShowPostModal] = useState(false);
  const [detail, setDetail] = useState();
  
  const [showPostModal2, setShowPostModal2] = useState(false);
  const [detail2, setDetail2] = useState();

  const [showPostModal3, setShowPostModal3] = useState(false);
  const [detail3, setDetail3] = useState();

  const [showPostModal4, setShowPostModal4] = useState(false);
  const [detail4, setDetail4] = useState();

  useEffect(()=> {

    async function loadTextos(){
      await firebase.firestore().collection('textos')
      .doc('RUNqoWqNjd76Hs8i9HWw')
      .get()
      .then((snapshot) => {
        setComplemento(snapshot.data().complemento);  
        setTexto1(snapshot.data().texto1);
        setTexto2(snapshot.data().texto2);
        setTexto3(snapshot.data().texto3);
        setTexto4(snapshot.data().texto4);
        setLinkvideo(snapshot.data().linkvideo);
        setTexto5(snapshot.data().texto5);
        setTexto6(snapshot.data().texto6)
      })

    }
    loadTextos();

    async function loadConfig(){
      await firebase.firestore().collection('config')
      .doc('GH3GCwt8q6huZCRz17eh')
      .get()
      .then((snapshot) => {
        setNomeconfig(snapshot.data().nomeconfig);
        setSloganconfig(snapshot.data().sloganconfig);
        setDesccurtaconfig(snapshot.data().desccurtaconfig);
        setPalavrachaveconfig(snapshot.data().palavrachaveconfig);
        setRazaoconfig(snapshot.data().razaoconfig);
        setCnpjconfig(snapshot.data().cnpjconfig);
        setCepconfig(snapshot.data().cepconfig);
        setLogradouroconfig(snapshot.data().logradouroconfig);
        setNumeroconfig(snapshot.data().numeroconfig);
        setComplementoconfig(snapshot.data().complementoconfig);
        setBairroconfig(snapshot.data().bairroconfig);
        setCidadeconfig(snapshot.data().cidadeconfig);
        setEstadoconfig(snapshot.data().estadoconfig);
        setPaisconfig(snapshot.data().paisconfig);
        setSiteconfig(snapshot.data().siteconfig);
        setEmailconfig(snapshot.data().emailconfig);
        setLinkinstagramconfig(snapshot.data().linkinstagramconfig);
        setLinkfbconfig(snapshot.data().linkfbconfig);
        setLinkyoutubeconfig(snapshot.data().linkyoutubeconfig);
        setLinkdriveconfig(snapshot.data().linkdriveconfig);
        setLinkdrivelegisconfig(snapshot.data().linkdrivelegisconfig);
        setLinkdrivetransconfig(snapshot.data().linkdrivetransconfig);
        setLinkdrivefotoconfig(snapshot.data().linkdrivefotoconfig);
        setLinklinkedconfig(snapshot.data().linklinkedconfig);
        setLinkapplestoreconfig(snapshot.data().linkapplestoreconfig);
        setLinkgoogleplayconfig(snapshot.data().linkgoogleplayconfig);
        setTelefone1config(snapshot.data().telefone1config);
        setTelefone2config(snapshot.data().telefone2config);
        setWhats1config(snapshot.data().whats1config);
        setWhats2config(snapshot.data().whats2config)
      })
    }
    loadConfig();

  
    async function loadId(){
      await firebase.firestore().collection('pontosinteresse').doc(id2)
      .get()
      .then((snapshot) => {
        setNomeponto(snapshot.data().nomeponto);
        setCidadeponto(snapshot.data().cidadeponto);
        setCategoriaponto(snapshot.data().categoriaponto);
        setTextosimplesponto(snapshot.data().textosimplesponto);
        setLogradouroponto(snapshot.data().logradouroponto);
        setNumeroponto(snapshot.data().numeroponto);
        setComplementoponto(snapshot.data().complementoponto);
        setGpsponto(snapshot.data().gpsponto);
        setLinkvideoponto(snapshot.data().linkvideoponto);
  
      })
      .catch((err)=>{
        console.log('ERRO NO ID PASSADO: ', err);
        setIdcidades(false);
      })
    }
    loadId();


        

    return () => {

    }
  }, []);



  async function updateStateparceiros(snapshot){
    const isCollectionEmpty4 = snapshot.size === 0;

    if(!isCollectionEmpty4){
      let lista4 = [];

      snapshot.forEach((doc)=>{
        lista4.push({
          id4: doc.id,
          avatarUrlparceiro: doc.data().avatarUrlparceiro,
          nomeparceiro: doc.data().nomeparceiro,
          linksiteparceiro: doc.data().linksiteparceiro,
          created: doc.data().created,
          createdFormated: format(doc.data().created.toDate(), 'dd/MM/yyyy')
        })
      })

      const lastDoc4 = snapshot.docs[snapshot.docs.length -1]; //Pegando o ultimo documento buscado
      setParceiros(parceiros => [...parceiros, ...lista4]);
      setLastDocs4(lastDoc4);
    }else{
      setIsEmpty4(true);
    }
    setLoadingMore4(false);
  }


  function togglePostModal(item){
    setShowPostModal(!showPostModal) //trocando de true pra false
    setDetail(item);
  }

  function togglePostModalImprensa(item2){
    setShowPostModal2(!showPostModal2) //trocando de true pra false
    setDetail2(item2);
  }
  
  function togglePostModalRota(item3){
    setShowPostModal3(!showPostModal3) //trocando de true pra false
    setDetail3(item3);
  }
  function togglePostModalEduca(item4){
    setShowPostModal4(!showPostModal4) //trocando de true pra false
    setDetail4(item4);
  }

  function onReady(event) {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
  }

    return(
      <div>
      <div className="Menufundo">
        <div className="Menu">
          <div>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <Link className="action" to={`/`} ><img src={logo} className="App-logotop"  alt="São João del Rei" /></Link>
          </div>
          <div>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Seja bem vindo!
          </div>
          <div>
            <img src={patrimoniominas} className="App-logotop"  alt="Minas Gerais" />
          </div>
        </div>
    </div>

      <div className="InicioTitulo" id="Início">
        Nome Ponto: {nomeponto}
      </div>
      <div className="Inicio">
       <YouTube videoId={linkvideoponto} opts={opts} onReady={onReady} />
      </div>
      <div className="InicioTitulo" id="Início">
        Um pouco sobre: {textosimplesponto} <br></br>
          
      </div>

      <div className="Inicio">
      <Maps1/>
      </div>

      <div className="Barra">
        <div className="Barrinha">&nbsp;&nbsp;&nbsp;</div>
      </div>

    
      <div className="Rodape" id="Contato">
        <div style={{width: 300, textAlign: "center", verticalAlign: "center",}}>
          <img src={logo} className="App-logoinferior"  alt="São João del rei" />
        </div>
        <div style={{width: 500, textAlign: "left", verticalAlign: "center",}}>
          {logradouroconfig}, {numeroconfig}, {complementoconfig}, 
          {bairroconfig}, {cepconfig}<br></br>
          {cidadeconfig},<br></br>
          {estadoconfig}, {paisconfig}.<br></br>
          {telefone1config} {telefone2config} <br></br>
          <a mailto={emailconfig}>{emailconfig}</a><br></br>
        </div>
  
        <div style={{width: 150, textAlign: "left", margin: 5,}}>
          <a href={linkfbconfig} target="_blank" ><FaFacebookSquare color="#000" size={25}/></a>&nbsp;&nbsp;
          <a href={linkinstagramconfig} target="_blank"><FaInstagram  color="#000" size={25}/></a>&nbsp;&nbsp;
      </div>
 
      </div>
      <div className="Barrafinal">
      Todos os direitos reservados © {razaoconfig}, {cnpjconfig} 
      </div>

      
      {showPostModal && (
        <Modalcidade
          conteudo={detail}
          close={togglePostModal}
        />
      )}
      {showPostModal2 && (
        <Modalimprensa
          conteudo2={detail2}
          close={togglePostModalImprensa}
        />
      )}
      {showPostModal3 && (
        <Modalrota
          conteudo3={detail3}
          close={togglePostModalRota}
        />
      )}
      {showPostModal4 && (
        <Modaleduca
          conteudo4={detail4}
          close={togglePostModalEduca}
        />
      )}
  </div>
  )
}